.ui-dialog.no-close .ui-dialog-titlebar-close {
    display: none;
}

.ui-dialog.no-close .ui-dialog-title {
    margin: 0px !important;
}

.ui-dialog.product-addons-popup {
    max-width: 85%;
}
