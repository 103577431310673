/* Bootstrap Overrides */
.alert .close {
    text-decoration: none;
}

.table th, .table td {
    border-top: none;
}

.table tr.no-border th, .table tr.no-border td {
    border: none;
}

table.border-rows table th, table.border-rows table td {
    border: none;
}

table.border-rows th, table.border-rows td{
    border-top: 1px solid #dddddd;
}

form {
    margin: 0;
}

legend {
    color: #333;
    width: auto;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
    border-bottom: none;
    padding: 0 5px;
}

fieldset {
    border: 1px solid #333;
    margin-bottom: 20px;
    padding: 15px;
}

input[type="radio"],
input[type="checkbox"] {
    margin-top: 0;
    margin-right: 3px;
}

.input-icon i {
    margin-left: -22px;
}

/* Daterange Picker */
.daterangepicker .active {
    background: none;
}

.daterangepicker th {
    background: none;
}

.daterangepicker .calendar {
    max-width: 288px;
}

.daterangepicker .daterangepicker_input .input-mini {
    width: 210px;
}

.daterangepicker .table-condensed th, .daterangepicker .table-condensed td {
    padding: 1px;
}

.daterangepicker .left {
    float: left;
    font-weight: normal;
    width: auto;
    margin-left: 0;
}

.daterangepicker .ranges .btn {
    background: none;
    border: 0;
    text-shadow: none;
    box-shadow: none;
    color: #0088CC;
    text-transform: uppercase;
    padding: 0;
    margin: 0 5px;
}

.daterangepicker .ranges .btn:first-child {
    margin-right: 20px;
    float: right;
}

.daterangepicker .ranges .btn:last-child {
    margin-left: 20px;
}

.daterangepicker .ranges li {
    background: none;
    color: #000;
    border: 1px solid white;
    padding: 2px 3px;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
    background: #757575;
    color: #FFF;
    border: 1px solid #757575;
    border-radius: 0;
}

.daterangepicker td.in-range {
    background-color: #f0f0f0;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #757575;
    border: none;
}

.daterangepicker .range_input {
    text-align: center;
}

.daterangepicker-field {
    position: relative;
}

.daterangepicker-field input {
    padding-left: 25px;
}

.daterangepicker-field input.input-large {
    width: 191px;
}

.daterangepicker-field i {
    position: absolute;
    left: 8px;
    top: 10px;
}

select {
    width: auto;
}

label {
    display: inline;
}

.pagination ul > li > a {
    color: #08c;
}

.pagination ul > li.active > a {
    color: #fff;
    background: #787878;
    border-color: #5e5e5e;
}
