/* BUTTONS */

.button_class {
    /* Used for the print buttons on the my curriculum reporting */
    float: right;
}

div.buttons button, div.buttons input {
    display: block;
    float: left;
}

div.buttons.noFloat {
    overflow: hidden;
    width: 100%;
}

.buttons button, .buttons input {
    margin: 0 3px 0 0;
    background-color: #ddd;
    border: 1px solid #999;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    line-height: 130%;
    text-decoration: none;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    padding: 5px 7px 6px 7px; /* Links */
}

div.buttons a {
    display: block;
    float: left;
    margin: auto;
    border: 1px solid #999;
    background-color: #ddd;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    line-height: 130%;
    text-decoration: none;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    padding: 5px; /* Links */
}

div.buttons.multi a {
    margin-right: 10px;
}

.buttons button {
    width: auto;
    overflow: visible;
    padding: 3px 3px 3px 3px; /* IE6 */
}

.buttons input {
    width: auto;
    overflow: visible;
    padding: 3px 10px 3px 20px;
}

.buttons button[type] {
    padding: 6px; /* Firefox */
    line-height: 13px; /* Safari */
}

*:first-child + html button[type] {
    padding: 3px 5px 3px 5px; /* IE7 */
}

.buttons button img, .buttons a img {
    margin: 0 3px -3px 0 !important;
    padding: 0;
    border: none;
    width: 16px;
    height: 16px;
    vertical-align: baseline;
}

.buttons a:hover {
    display: block;
    float: left;
    border: 1px solid #777;
    background-color: #bbb;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    line-height: 130%;
    text-decoration: none;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    padding: 5px; /* Links */
}

.buttons button:hover, .buttonsnf button:hover {
    border: 1px solid #777;
    background-color: #bbb;
    color: #333;
}

.buttons a:active {
    background-color: #6299c5;
    border: 1px solid #6299c5;
    color: #fff;
    text-decoration: none;
}

.buttons a:visited {
    text-decoration: none;
}

/* POSITIVE */

button.positive, .buttons a.positive {
    color: #529214;
}

.buttons a.positive:hover, button.positive:hover {
    background-color: #E6EFC2;
    border: 1px solid #C6D880;
    color: #529214;
}

.buttons a.positive:active {
    background-color: #529214;
    border: 1px solid #529214;
    color: #fff;
}

/* NEGATIVE */

.buttons a.negative, button.negative {
    color: #d12f19;
}

.buttons a.negative:hover, button.negative:hover {
    background: #fbe3e4;
    border: 1px solid #fbc2c4;
    color: #d12f19;
}

.buttons a.negative:active {
    background-color: #d12f19;
    border: 1px solid #d12f19;
    color: #fff;
}

/*Credits: Dynamic Drive CSS Library */
/*URL: http://www.dynamicdrive.com/style/ */

.buttonwrapper a.squarebutton, button.squarebutton {
    background: transparent url('/images/buttons/btn_left.png') no-repeat top left;
    display: block;
    font: bold 11px Arial; /* Change 12px as desired */
    line-height: 19px; /* This value + 4px + 4px (top and bottom padding of SPAN) must equal height of button background (default is 23px) */
    height: 27px; /* Height of button background height */
    padding-left: 5px; /* Width of left menu image */
    text-decoration: none;
    margin: 0 0;
    text-align: center;
}

a:link.squarebutton, a:visited.squarebutton, a:active.squarebutton {
    color: #333; /*button text color*/
    text-decoration: none;
}

a.squarebutton span {
    background: transparent url('/images/buttons/btn_right.png') no-repeat top right;
    display: block;
    padding: 4px 6px 4px 0; /*Set right padding below to match value of 'padding-left' value above*/
}

a.squarebutton:hover { /* Hover state CSS */
    background-position: bottom left;
    text-decoration: none;
}

a.squarebutton:hover span { /* Hover state CSS */
    background-position: bottom right;
    color: black;
    text-decoration: none;
}

.buttonwrapper { /* Container you can use to surround a CSS button to clear float */
    overflow: hidden; /*See: http://www.quirksmode.org/css/clearing.html */
    width: 100%;
}

button.squarebutton {
    background: transparent url('/images/buttons/btn_right.png') no-repeat top right;
    display: block;
    float: left;
    font: bold 11px Arial; /* Change 12px as desired */
    line-height: 19px; /* This value + 4px + 4px (top and bottom padding of SPAN) must equal height of button background (default is 23px) */
    height: 27px; /* Height of button background height */
    padding-left: 6px; /* Width of left menu image */
    text-decoration: none;
    margin: 0 auto;
    border: none;
}

button.squarebutton div {
    background: transparent url('/images/buttons/btn_right.png') no-repeat top right;
    display: inline;
}

.exam_launch {
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
    padding: 2px;
    background-color: white;
    border: 0px;
}

div.login_btn {
    float: left;
}

input.login_btn {
    border: 0px;
}

.buttons input.add,
.buttons input.add_users,
.buttons input.accept,
.buttons input.close,
#purchased-before-wrapper .create-account,
#purchased-before-wrapper .log-in {
    background-color: #b3b3b3;
    color: #ffffff;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    float: none;
}

.buttons input.primary-button,
.buttons a.primary-button,
.buttons input.secondary-button,
.buttons a.secondary-button,
.buttons input.proceed-button,
.buttons a.proceed-button {
    padding: 6px 20px !important;
    line-height: 100% !important;
    color: #ffffff;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    float: none;
    border: none;
    font-size: 12px;
}

.input-append input.primary-button,
.input-append a.primary-button,
.input-append input.secondary-button,
.input-append a.secondary-button,
.input-append input.proceed-button,
.input-append a.proceed-button {
    border-radius: 0px;
    line-height: 30px !important;
    padding: 0px 20px !important;
}

.buttons input.primary-button:focus,
.buttons a.primary-button:focus,
.buttons input.secondary-button:focus,
.buttons a.secondary-button:focus,
.buttons input.proceed-button:focus,
.buttons a.proceed-button:focus,
.buttons input.primary-button:active,
.buttons a.primary-button:active,
.buttons input.secondary-button:active,
.buttons a.secondary-button:active,
.buttons input.proceed-button:active,
.buttons a.proceed-button:active {
    outline: none;
}

.buttons input.primary-button,
.buttons a.primary-button {
    background-color: #888888;
}

.buttons input.primary-button:disabled,
.buttons a.primary-button:disabled {
    background-color: #dcdcdc !important;
    cursor: not-allowed;
}

.buttons input.proceed-button:disabled,
.buttons a.proceed-button:disabled {
    background-color: #B6D3B1 !important;
    cursor: not-allowed;
}

.buttons input.primary-button:hover,
.buttons a.primary-button:hover,
.buttons input.primary-button:active,
.buttons a.primary-button:active {
    background-color: #5e5e5e;
}

.buttons input.secondary-button,
.buttons a.secondary-button {
    background-color: #b3b3b3;
}

.buttons input.secondary-button:hover,
.buttons a.secondary-button:hover,
.buttons input.secondary-button:active,
.buttons a.secondary-button:active {
    background-color: #767676;
}

.buttons input.proceed-button,
.buttons a.proceed-button {
    background-color: #559548;
}

.buttons input.proceed-button:hover,
.buttons a.proceed-button:hover,
.buttons input.proceed-button:active,
.buttons a.proceed-button:active {
    background-color: #3d6b33;
}

.paging-buttons [type=button] {
    margin-left: 3px;
    font-size: 90%;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-weight: bold;
    line-height: 100%;
    padding: 12px;
    color: #333;
    border: 1px solid #ccc;
    background: white;
}

.buttons input.wide,
.buttons a.wide {
    width: 100%;
    text-align: center;
}

.buttons a.wide {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fancy-button {
    margin: 0.5ex !important;
}

.user-list-buttons {
    text-align: center;
    float: left;
    width: 49.9%;
    margin: 0px;
    padding: 0px;
}

.checkout-dialog-buttons > :first-child,
.addon-buttons > :first-child,
.confirm-dialog-buttons > :first-child {
    float: left;
}

.delete-users-buttons form {
    display: inline;
}

#purchased-before-wrapper .create-account,
#purchased-before-wrapper .log-in {
    border: initial;
}

#purchased-before-wrapper .log-in {
    margin-top: 24px;
    width: 200px;
}

.buttons input.close {
    padding: 5px 24px;
    font-size: 90% !important;
    display: inline;
    float: none;
    opacity: 1;
    text-shadow: none;
}

.calendar-btn {
    margin-right: 50px !important;
}

.buttons input.accept {
    width: 222px;
}

.buttons input.add_users {
    float: right;
    margin-right: 60px !important;
    width: 125px;
}

.buttons input.save {
    background: #ddd url('/images/button_icons/disk.png') no-repeat 3px 3px;
}

.buttons input.cancel {
    background: #ddd url('/images/button_icons/cancel.png') no-repeat 3px 3px;
}

.buttons input.delete {
    background: #ddd url('/images/button_icons/delete.png') no-repeat 3px 3px;
}

.buttons input.address {
    background: #ddd url('/images/button_icons/vcard_edit.png') no-repeat 3px 3px;
}

.buttons input.pkg {
    background: #ddd url('/images/button_icons/package.png') no-repeat 3px 3px;
}

.buttons input.pkg_add {
    background: #ddd url('/images/button_icons/package_add.png') no-repeat 3px 3px;
}

.buttons input.pkg_del {
    background: #ddd url('/images/button_icons/package_delete.png') no-repeat 3px 3px;
}

.buttons input.pkg_go {
    background: #ddd url('/images/button_icons/package_go.png') no-repeat 3px 3px;
}

.buttons input.calc {
    background: #ddd url('/images/button_icons/calculator.png') no-repeat 3px 3px;
}

.buttons input.cog {
    background: #ddd url('/images/button_icons/cog.png') no-repeat 3px 3px;
}

.buttons input.cog_edit {
    background: #ddd url('/images/button_icons/cog_edit.png') no-repeat 3px 3px;
}

.buttons input.cog_add {
    background: #ddd url('/images/button_icons/cog_add.png') no-repeat 3px 3px;
}

.buttons input.cog_del {
    background: #ddd url('/images/button_icons/cog_delete.png') no-repeat 3px 3px;
}

.buttons input.license {
    background: #ddd url('/images/button_icons/cart_go.png') no-repeat 3px 3px;
}

.buttons input.cog_go {
    background: #ddd url('/images/button_icons/cog_go.png') no-repeat 3px 3px;
}

.buttons input.images {
    background: #ddd url('/images/button_icons/images.png') no-repeat 3px 3px;
}

.buttons input.bkopen {
    background: #ddd url('/images/button_icons/book_open.png') no-repeat 3px 3px;
}

.buttons input.user_gray {
    background: #ddd url('/images/button_icons/user_gray.png') no-repeat 3px 3px;
}

.buttons input.filter {
    background: #ddd url('/images/button_icons/zoom.png') no-repeat 3px 3px;
}

.buttons input.elec_qbank {
    background: #ddd url('/images/button_icons/report_add.png') no-repeat 3px 3px;
}

.buttons input.print_qbank {
    background: #ddd url('/images/button_icons/printer_add.png') no-repeat 3px 3px;
}

.buttons input.change_pw {
    background: #ddd url('/images/button_icons/lock_add.png') no-repeat 3px 3px;
}

.buttons input.policy {
    background: #ddd url('/images/button_icons/script.png') no-repeat 3px 3px;
}

.buttons input.user_add {
    background: #ddd url('/images/button_icons/user_add.png') no-repeat 3px 3px;
}

.portal-expand {
    width: 13px;
    height: 10px;
    border: 1px solid #abc;
    background: transparent url('/images/arrow_expand.gif') no-repeat top left;
}

.portal-collapse12 {
    width: 13px;
    height: 10px;
    border: 1px solid #abc;
    background: transparent url('/images/arrow_collapse.gif') no-repeat top left;
}

/****************************** Added on Mar 27, 2009 - Login Headers CSS **************************/

table.kfe_login_users_tables {
    float: left;
}

th.kfe_login_users_header {
    background-image: url('/images/menus/login_bck.gif');
    background-repeat: repeat-x;
    padding: 2px 8px 2px 12px;
}

table.kfe_login_users_table {
    border: 1px solid #cacaca;
    margin-top: -1px;
    padding: 0px 3px 0px 3px;

}

table.kfe_new_users_tables {
    margin-left: 13px;
    padding:0px;
    float:left;
}

th.kfe_new_users_header {
    background-image: url('/images/menus/login_bck.gif');
    background-repeat: repeat-x;
    padding: 2px 8px 2px 12px;
}

table.kfe_new_users_table {
    border: 1px solid #cacaca;
    margin-top: -1px;
    padding: 0px 3px 0px 3px;
    height: 112px;
}

/*********** KFE and KFE Portal Login CSS - March 3, 2009 *****************/

input.kfe_login_btn {
    display: block;
    background-color: #dddddd;
    margin-right: 0px;
    padding: 5px 0px 5px 20px;
    width: 65px;
    border: 1px solid #999999;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-image: url('/images/button_icons/key_go.png');
    background-repeat: no-repeat;
    background-position: 3px 3px;
}

input.training_btn {
    display: block;
    background-color: #dddddd;
    margin-right: 0px;
    padding: 5px 0px 5px 20px;
    border: 1px solid #999999;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-image: url('/images/button_icons/email_go.png');
    background-repeat: no-repeat;
    background-position: 3px 3px;
    float: right;
}

input.kfe_portal_login_btn {
    display: block;
    background-color: #dddddd;
    margin-right: 0px;
    padding: 5px 0px 5px 20px;
    width: 75px;
    border: 1px solid #999999;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-image: url('/images/button_icons/user_add.png');
    background-repeat: no-repeat;
    background-position: 3px 3px;
}

.cart_add_btn {
    display: block;
    background-color: #dddddd;
    margin-right: 0px;
    padding: 5px 0px 5px 20px;
    width: 65px;
    border: 1px solid #999999;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-image: url('/images/button_icons/cart_add.png');
    background-repeat: no-repeat;
    background-position: 3px 3px;
    text-align: center;
}

a.cart_add_btn:link, a.cart_add_btn:active, a.cart_add_btn:visited {
    text-decoration: none;
}

a.cart_add_btn:hover {
    background-color: #bbbbbb;
}

.learn_more_btn {
    display: block;
    background-color: #dddddd;
    margin-right: 0px;
    padding: 5px 10px 5px 10px;
    width: 65px;
    border: 1px solid #999999;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 90%;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-image: url('/images/button_icons/dash_db.png');
    background-repeat: no-repeat;
    background-position: 65px 7px;
    text-align: left;
}

a.learn_more_btn:link, a.learn_more_btn:active, a.learn_more_btn:visited {
    text-decoration: none;
}

a.learn_more_btn:hover {
    background-color: #bbbbbb;
}

div.logins_wrapper {
    /*width:570px;*/
    width: auto;
}

/*** Safari CSS hack ***/
html > body * input.kfe_portal_login_btn {
    padding: 5px 0px 5px 20px;
}

html > body * input.kfe_login_btn {
    padding: 8px 0px 6px 20px;
    margin-top: 0px;
}

input.add_preorder {
    color: #fff;
    font: bold 13px Helvetica, Arial, Helvetica, Sans-Serif !important;
    padding: 5px 8px 5px 8px !important;
    background-color: #666;
    border: 1px solid #d5d5d5;
    border-top: 1px solid #aeaeae;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: rgba(213, 213, 213, 1) 0 1px 0;
    -moz-box-shadow: rgba(213, 213, 213, 1) 0 1px 0;
    box-shadow: rgba(213, 213, 213, 1) 0 1px 0;
    cursor: pointer;
    width: auto;
    overflow: visible;
    text-decoration: none !important;
}

input.add_preorder:hover {
    background-color: rgba(122, 122, 122, 0.5);
    background-color: #7a7a7a;
}

.enhanced-buttons-row .enhanced-buttons-container {
    float: right;
    text-align: right;
    padding-right: 13px;
    height: 20px;
}

.enhanced-buttons-row .enhanced-text-button,
.enhanced-buttons-row .enhanced-buttons-container .enhanced-text-button
{
    color: #0088CC;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    border: none;
    background-color: inherit;
    padding: 0;
    margin-left: 25px;
}

/* Live Chat Icons on Contact Us page classes */

#live_chat {
    height: 43px;
    width: 229px;
    background: url(/images/button_images/live_chat.gif) 0 0 no-repeat;
    display: block;
    margin: 8px auto 2px auto;
}

#live_chat:hover, #live_chat_kf:hover, #live_chat_re:hover, #live_chat_ku:hover {
    background: url(/images/button_images/live_chat.gif) 0 -47px no-repeat;
}

.chat_disabled {
    background: url(/images/button_images/live_chat.gif) 0 -93px no-repeat !important;
}

/* End Live Chat Icons ************************/
