@import url("bootstrap-overrides.css");
@import url("bg.css");
@import url("buttons.css");
@import url("dialog.css");

body {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	margin: 0;
}

a, a:active, a:visited {
    cursor: pointer;
	font-family: Arial, Helvetica, sans-serif;
	color: #333;
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
}

#menu_top_sep {
	width: 100%;
	height: 5px;
	background: #1d2071 url(/images/menus/spacer_bg_white.gif) repeat-x top left;
	line-height:1px; padding: 0;
	clear:both;
	font-size:1px;
	margin:0;
}

#menu_top_sep2 {
	width: 100%;
	height: 5px;
	line-height:1px; padding: 0;
	clear:both;
	font-size:1px;
	margin:0;
}

td {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #000;
}

th {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	background-color: #ccc;
	color: #000;
	text-decoration: none;
}

td.mainContent{
	background-color: #fff;
	padding: 5px 10px 10px 24px;
}
td.mainContent2{
	background-color: #fff;
	padding: 0;
}

td.content_footer {
	font-size: 8pt;
	text-align: center;
	background-color: #eee;
	border-bottom: 1px solid #cecece;
	padding: 5px 0;
	}
td.content_footer a {
	font-size: 8pt;
}

td.content_footer a, .content_footer a:active  {
	text-decoration: none;
	font-size: 8pt;
}

td.content_footer a:visited {
	color: #555;
	font-size: 8pt;
}

td.content_footer a:hover {
	text-decoration: underline;
	font-size: 8pt;
}

.disclaimerTable2 {
	width:775px;
}

.disclaimerTable {
	width:775px;
}

.disclaimer {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
	color: #999;
	text-decoration: none;
	padding: 10px;
	padding-top:5px;
}

.disclaimer p {
	font-weight: normal;
	color: #555;
	font-size: 8pt;
	line-height:1.2em;
}

.disclaimer p a, .disclaimer p a:active {
	font-size: 8pt;
	text-decoration: underline;
	line-height:1em;
	margin:0;
	padding:0;
}

.disclaimer p a:hover {
	text-decoration: none;
}

td.seminar_region_header {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	color: #333;
}

td.super_value {
	text-align: center;
	background-color: #ccc;
	line-height: 20px;
	border-bottom: 1px solid #000;
	padding: 2px;
	color: #333;
}
A.submenu:link, A.submenu:visited {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #fff;
	text-decoration: none;
}

A.submenu:hover {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #ccc;
	text-decoration: none;
}

img.buttonCursor { cursor: pointer; }
A.submenu:active {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #fff;
	text-decoration: none;
}
A.fast_facts:link, A.fast_facts:visited {
	font-size: 8pt;
	text-decoration: underline;
}

A.fast_facts:hover {
	font-size: 8pt;
	text-decoration: none;
}
A.fast_facts:active {
	font-size: 8pt;
	text-decoration: underline;
}
A.seminar:link, A.seminar:visited {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;
	text-decoration: underline;
}
A.seminar:hover {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
}
A.seminar:active {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;
	text-decoration: underline;
}
a.box:link, a.box:visited,a.box:active {
	text-decoration: none;
	color: #666;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	padding-left: 5px;
	padding-right: 5px;
}
a.box:hover {
	color: #ccc;
}

a.main-small:link, a.main-small:visited, a.main-small:active {
	font-family: Arial, Helvetica, sans-serif;
	color: #014897;
	font-weight: bold;
	font-size: 8pt;
	text-decoration: underline;
}
a.main-small:hover {
	text-decoration: none;
}

a.small:link, a.small:visited {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	font-size: x-small;
	text-decoration: underline;
}
a.small:hover {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	font-size: x-small;
	text-decoration: none;
}
a.small:active {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	font-size: x-small;
	text-decoration: underline;
}
A.announcements:link, A.announcements:visited {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	color: #014897;
	font-size: 12px;
	text-decoration: none;
}
A.announcements:hover {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	color: #014897;
	font-size: 12px;
	text-decoration: underline;
}
A.announcements:active {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	color: #014897;
	font-size: 12px;
	text-decoration: none;
}
a.no_decoration {
	text-decoration: none;
}
.details {
	font-size: smaller;
	text-decoration: underline;
}
.applet_header {
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	font-size: 18px;
	font-weight: bold;
	text-indent: 8px;
	text-align: center;
}
.applet_subheader {
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	font-size: 16px;
	font-weight: bold;
	margin-left: 1em;
	margin: 10px;
}
.header {
	font-family: Arial, Helvetica, sans-serif;
	color: #014897;
	font-size: 16px;
	font-weight: bold;
	text-indent: 8px;
}
.subheader {
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	font-size: 12px;
	font-weight: bold;
	text-indent: 8px;
}
.main-header {
	font-family: Verdana;
	color: #008080;
	font-size: 18px;
}
.main-head-txt {
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	font-weight: bold;
	font-size: 12px;
}
.swBlueL3 {
	background-color: #0A286E;
	border: thin solid #666;
	color: #fff;
}
.swMaroonL2 {
	height: 10px;
	width: 15px;
	background-color: #780A0A;
	border: thin solid #666;
	color: #fff;
}
.swGreenL1 {
	height: 10px;
	width: 15px;
	background-color: #0A5032;
}
.swFrm {
	height: 10px;
	width: 15px;
	background-color: #800080;
}
.vertical-dot {
	background-image: url('/images/dot.gif');
	background-repeat: repeat-y;
}
.horizontal-dot {
	background-image: url('/images/dot.gif');
	background-repeat: repeat-x;
}
.solid-left {
	border-left: 3px solid #000;
}
.solid-bottom {
	border-bottom: 3px solid #000;
}
A.leftnavheader:link, A.leftnavheader:visited, A.leftnavheader:hover, A.leftnavheader:active {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
}
div.nav_header span.leftnavheader {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
}
.leftnavheader {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
}
#leftnav_list {
	margin-left: 0;
	padding-left: 0;
	padding-top: 2px;
	padding-bottom: 2px;
	list-style: none;
}
#leftnav_list li {
	padding-left: 10px;
	background-image: url(/images/gt.gif);
	background-repeat: no-repeat;
	background-position: 0 .5em;
}
#leftnav_list li.down {
	padding-left: 10px;
	background-image: url(/images/raquot_down.gif);
	background-repeat: no-repeat;
	background-position: 0 .5em;
}
.leftnav_line {
	height: 1px;
	background: url(/includes/menu/img/separator_dots4.jpg);
	background-repeat: repeat-x;
	background-position: center;
	padding-top: 2px;
	padding-bottom: 2px;
}
UL {
	list-style: square;
}
LI.number {
	list-style-type: decimal;
}
LI.letter {
	list-style-type: lower-alpha;
}
A.checkout:link, A.checkout:visited, A.checkout:hover, A.checkout:active {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #fff;
	text-decoration: none;
}

A.login:link, A.login:visited {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #333;
	font-weight: bold;
	text-decoration: none;
}

A.login:hover {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #ccc;
	font-weight: bold;
	text-decoration: none;
}
A.login:active {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #333;
	font-weight: bold;
	text-decoration: none;
}
A.tip-of-week:link, A.tip-of-week:visited, A.tip-of-week:hover, A.tip-of-week:active {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
	border: 1px solid #000;
	padding: 1px;
	background-color: #E4E4E4;
	text-decoration: none;
}

.pulldown, .pulldown_white {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	background-color: #fff;
	font-weight: normal;
	text-decoration: none;
}

div.bodyheader {
	text-indent: 8px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #fff;
	letter-spacing: 1px;
}

div.box_text {
	width: 98%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	color: #000;
	font-weight: normal;
}

.user-pass {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
}
.announcement-date {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 9pt;
	font-weight: bold;
}

fieldset {
	padding: 8px 5px;
}

legend {
	color: #333;
}
p.announceHeader { color:#333; font-size:12pt; font-weight:bold; }
div.announceDate { font-size: 9pt; color:#666; font-weight: bold; margin-bottom:8px; }
td.announceRow { background-color:#eee; }
table.announceTable { width:100%; border:1px solid #333; }
td.announceHeader {
	color: #fff;
	font-weight: bold;
	background-color: #333;
}

A.back_button:link, A.back_button:visited {
	border: 1px solid #000;
	color: #6CC22F;
	font-weight: bold;
	text-decoration: none;
	padding-left: 3px;
	padding-right: 2px;
}

A.back_button:hover {
	border: 1px solid #000;
	color: #000;
	font-weight: bold;
	text-decoration: none;
	padding-left: 3px;
	padding-right: 2px;
}
A.back_button:active {
	border: 1px solid #000;
	color: #6CC22F;
	font-weight: bold;
	text-decoration: none;
	padding-left: 3px;
	padding-right: 2px;
}
table.product {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	width: 560px;
	border-collapse: collapse;
}
table.product_open {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	width: 560px;
	border-collapse: collapse;
}
td.product {
	border-bottom: 1px solid #000;
	height: 22px;
	padding: 3px;
	vertical-align: middle;
	color: black;
}
td.product form {
	margin: 0;
	padding: 0;
}
th.product {
	border-bottom: 1px solid #000;
	height: 22px;
	padding: 3px;
	color: black;
}
.standard_table {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
}
.standard_cell, .botbord_cell {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
}
.botbordonly_cell {
	border-bottom: 1px solid #000;
}
.rightbord_cell {
	border-right: 1px solid #000;
}
A.toggle_link {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	text-decoration: none;
	cursor: pointer;
}

div.level_select {
	padding: 5px;
}

.region_select
{
  font-weight:bold;
  margin-top:15px;
}

.learning_question
{
  margin:10px;
}
.learning_question span
{
  margin-bottom:15px;
  font-weight:bold;
}
.learning_question div
{
  margin-left:10px;
}

a.kaplan:link, a.kaplan:visited {
	text-decoration: none;
	font-weight: bold;
	color: #666;
	font-size: 8pt;
}

a.index:link, a.index:visited {
	text-decoration: none;
	color: #666;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
}
a.index:hover {
	text-decoration: none;
	color: #ccc;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
}
a.index:active {
	text-decoration: none;
	color: #666;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
}
.simple_table {
	border: 1px solid #000;
	border-collapse: collapse;
	text-align: center;
	empty-cells: show
}
.simple_table td, .simple_table th {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	padding: 3px;
}

table.standard {
	border: 1px solid #000;
	border-collapse: collapse;
	empty-cells: show
}
table.standard td, table.standard th {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	padding: 3px;
}
table.standard th {
  color:#000;
  background-color: #dcdcdc;
}

.pkg_table th {
	color: #fff;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 9pt;
	padding: 10px 3px;
	border-bottom: 1px solid #000;
}

.pkg_table th a:link, .pkg_table th a:visited {
	color: #fff;
	font-size: 9pt;
	font-weight: bold;
	text-decoration: none;
	font-family: Verdana, Arial, Helvetica, sans-serif;
}
.pkg_table th a:hover {
	color: #fff;
	font-size: 9pt;
	font-weight: bold;
	text-decoration: underline;
	font-family: Verdana, Arial, Helvetica, sans-serif;
}
.pkg_table th a:active {
	color: #fff;
	font-size: 9pt;
	font-weight: bold;
	text-decoration: none;
	font-family: Verdana, Arial, Helvetica, sans-serif;
}
.pkg_table td {
	padding: 3px 0 3px 5px;
}
td.norightbord {
	border-right: none;
}

td.extrapad, td.extrapad_bot_bord {
	padding-bottom: 15px;
}

.pkg_table td ul {
	padding: 0 auto;
	margin: 0 auto;
}

td.cat_head {
	padding: 0;
	margin: 0;
	border-right: none;
	background-color: #D8D8D8;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
}

td.cat_head p {
	padding: 2px 5px;
	margin: 0;
	font-weight: bold;
	font-style: italic;
	font-size: 11pt;
}

td.pkgprice {
	font-weight: bold;
}

.smalltxt, .smalltxt a, .smalltxt a:visited, .smalltxt a:active,
.smalltext, .smalltext a, .smalltext a:visited, .smalltext a:active,
tabtable .smalltext, tabtable tr td div .smalltext a, tabtable .smalltext a:visited, tabtable .smalltext a:active,
li .smalltxt, li .smalltxt a, li .smalltxt a:visited, li .smalltxt a:active,
li .smalltext, li .smalltext a, li .smalltext a:visited, li .smalltext a:active
{
	font-size: 8pt;
}

.smalltxt a:hover, .smalltext a:hover,
li .smalltxt a:hover, li .smalltext a:hover {
	font-size: 8pt;
	text-decoration: none;
}

.smallgray {
	color:#999;
	font-size: 8pt;
	font-weight: normal;
}

.gray {
	color:#999;
	font-weight: normal;
}

.inactive {
	color: #666;
	background-color: #ccc;
}

.societies {
	margin: 0 0 20px 0;
}
.societies ul {
	font-size: 9pt;
	list-style: none;
	margin: 0;
}
.societies ul li {
	font-size: 9pt;
	margin: 0;
	padding: 3px 0;
}

.box_text ol li {
	margin-right: 20px;
}

.clearboth {
	clear: both;
}
.mastertabgroup {
	margin-bottom: 15px;
	text-align: left;
}
.tabgroupcontent {
	clear: both;
}
.tab {
	padding: 3px 10px;
	border-top: 1px solid #9fa0a2;
	cursor: pointer;
	height: 100%;
	font-weight: bold;
	text-align: center;
	font-size: 8pt;
	font-family: Verdana;
}
.tabactive {
	background-color: #fff;
}
.tabinactive {
	background-color: #D1D1D1;
	color: #000;
	border-bottom: 1px solid #9FA0A2;
}
.tabcontent {
	background-color: #fff;
	color: #000;
	padding: 9px 9px 9px 9px;
	border-bottom: 1px solid #9FA0A2;
	border-left: 1px solid #9FA0A2;
	border-right: 1px solid #9FA0A2;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
}
div.tabcontent a:link, div.tabcontent a:hover, div.tabcontent a:active, div.tabcontent a:visited, div.tabcontent li {
	font-size: 8pt;
}
.tabstrip {
	background: url('/images/tab_parts/main_bottom.gif');
	background-repeat: repeat-x;
	background-position: bottom;
}
.tabtable {
	empty-cells: show;
}
.tabextra {
}
.middle_default {
	height: 100%;
	background: url('/images/tab_parts/middle_default_middle.gif');
	border-bottom: 1px solid #9FA0A2;
	vertical-align: top;
}
.left_active {
	height: 100%;
	background: url('/images/tab_parts/left_active_middle.gif');
	vertical-align: top;
}
.left_default {
	height: 100%;
	background: url('/images/tab_parts/left_default_middle.gif');
	border-bottom: 1px solid #9FA0A2;
	vertical-align: top;
}
.right_default {
	height: 100%;
	background: url('/images/tab_parts/right_default_middle.gif');
	border-bottom: 1px solid #9FA0A2;
	vertical-align: top;
}
.right_active {
	height: 100%;
	background: url('/images/tab_parts/right_active_middle.gif');
	vertical-align: top;
}
.middle_left_active {
	height: 100%;
	background: url('/images/tab_parts/middle_left_active_middle.gif');
	vertical-align: top;
}
.middle_right_active {
	height: 100%;
	background: url('/images/tab_parts/middle_right_active_middle.gif');
	vertical-align: top;
}

.reg {
	font-size: .7em;
	vertical-align: text-top;
}


.reqmarker {
	color: red;
	font-weight: bold;
}

.red {
	color: #f00;
}

#hp ul.yui-nav {
	margin: 0;
	padding: 0;
}

.row_hl {
	background-color: #e6e6e6;
}

.mainTable {
	text-align:left;
	margin:0;
	border-left:1px solid #ccc;
	border-right:1px solid #ccc;
	width:776px;
	background-color:#fff;
	padding:0;
}

div.hide {
	padding: 0 5px 0 20px;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	font-weight: normal;
	margin:0;
}
div.new_hide_head {
	display: block;
	padding: 0 0 0 13px;
	margin-left: 5px;
    font-weight: bold;
    text-decoration: underline;
}

div.intended_header {
	display: block;
	padding: 0 0 0 13px;
	margin-left: 25px;
    font-weight: normal;
    text-decoration: underline;
}

div.hide_head h3 a.collapsed,
div.hide_head p a.collapsed {
	background: url(/images/menus/arrow_collapsed.gif) center left no-repeat;
	display: block;
	padding: 0 0 0 13px;
	margin-left: 5px;
}

div.hide_head h3 a.collapsed:hover,
div.hide_head p a.collapsed:hover {
	background: url(/images/menus/arrow_collapsed.gif) center left no-repeat;
}

div.hide_head h3 a.expanded,
div.hide_head h3 a.expanded:active,
div.hide_head h3 a.expanded:visited,
div.hide_head p a.expanded,
div.hide_head p a.expanded:active,
div.hide_head p a.expanded:visited {
	text-decoration: none;
	display: block;
	padding: 0 0 0 13px;
	margin-left: 5px;
	background: url(/images/menus/arrow_expand.gif) center left no-repeat;
}

div.hide_head h3 a.expanded:hover,
div.hide_head p a.expanded:hover {
	background: url(/images/menus/arrow_expand.gif) center left no-repeat;
}
.rnd_box_float {
	float: right;
	padding: 15px 15px 10px 10px;
	background-color:#fff;
}

.rnd_box_content {
	border: 1px solid #9fa0a2;
	cursor: default;
	text-align: left;
	font-size: 8pt;
	padding: 0 0 4px 0;
}

.rnd_box_content ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.rnd_box_content ul li {
	list-style-type: none;
	margin: 0;
	padding: 2px 6px;
}

.rnd_box_content dl {
	margin: 0;
	padding: 0;
}

.rnd_box_content dl dt {
	margin: 4px 0 0 0;
	padding: 0 0 0 3px;
	font-weight: bold;
	background: url(/images/breadcrumb_gradient_reverse.gif) left center repeat-x;
	font-size: 8pt;
	border-bottom: 1px solid #1d0d69;
}

.rnd_box_content dl dd {
	margin: 0;
	padding: 2px 6px;
}

.rnd_box_lcnr {
	background: #d1d1d1 url(/images/tab_parts/left_default_top_new.gif) top left no-repeat;
}

.rnd_box_rcnr {
	background: #d1d1d1 url(/images/tab_parts/right_default_top_new.gif) top right no-repeat;
}

.rnd_box_top_mid {
	padding: 3px 10px;
	border-top: 1px solid #9fa0a2;
	cursor: pointer;
	height: 100%;
	font-weight: bold;
	text-align: center;
	font-size: 8pt;
	font-family: Verdana;
	background-color: #d1d1d1;
}

table.allborder {
	border-collapse: collapse;
}

.allborder td {
	border: 1px solid #333;
}

ol.padded li, ul.padded li {
	padding: 0 0 15px 0;
}

a.ce_collapsed, a.ce_collapsed:active, a.ce_collapsed:visited {
	text-decoration: none;
	display: block;
	font-size: 12px;
	font-weight: bold;
	padding: 1px 10px 2px 11px;
	background: url(/images/menus/arrow_collapsed.gif) left no-repeat;
}

a.ce_expanded, a.ce_expanded:active, a.ce_expanded:visited {
	text-decoration: none;
	display: block;
	font-size: 12px;
	font-weight: bold;
	padding: 1px 10px 2px 11px;
	background: url(/images/menus/arrow_expand.gif) left no-repeat;
}

a.hp_collapsed, a.hp_collapsed:active, a.hp_collapsed:visited {
	text-decoration: none;
	display: block;
	color: #555;
	font-size: 12px;
	font-weight: bold;
	padding: 2px 10px 2px 5px;
	background: url(/images/menus/plus.gif) right top no-repeat;
}

a.hp_collapsed:hover {
	color: #777;
	background: url(/images/menus/plus_on.gif) right top no-repeat;
}

a.hp_expanded, a.hp_expanded:active, a.hp_expanded:visited {
	text-decoration: none;
	display: block;
	color: #555;
	font-size: 12px;
	font-weight: bold;
	padding: 2px 10px 2px 5px;
	background: url(/images/menus/minus.gif) right top no-repeat;
}

a.hp_expanded:hover {
	color: #777;
	background: url(/images/menus/minus_on.gif) right top no-repeat;
}

a.hp_menu_nonexpand, a.hp_menu_nonexpand:active, a.hp_menu_nonexpand:visited {
	text-decoration: none;
	color: #555;
	font-size: 12px;
	display: block;
	font-weight: bold;
	padding: 2px 10px 2px 5px;
}

a.hp_menu_nonexpand:hover {
	color: #777;
}

div#cfa_exams ul, div#cfp_exams ul, div#cpa_exams ul,
div#caia_exams ul, div#ia_exams ul, div#pd_exams ul {
	margin: 0 0 5px 0;
	padding: 1px 0 1px 35px;
}

div#cfa_exams ul li, div#cfp_exams ul li, div#cpa_exams ul li,
div#caia_exams ul li, div#ia_exams ul li, div#pd_exams ul li{
	margin: 0;
	padding: 0;
}

div#cfa_exams ul li a, div#cfp_exams ul li a, div#cpa_exams ul li a,
div#caia_exams ul li a, div#ia_exams ul li a, div#pd_exams ul li a {
	text-decoration: none;
	color: #555;
	font-size: 12px;
	font-weight: bold;
}

div#cfa_exams ul li a:hover, div#cfp_exams ul li a:hover, div#cpa_exams ul li a:hover,
div#caia_exams ul li a:hover, div#ia_exams ul li a:hover, div#pd_exams ul li a:hover {
	color: #999;
	font-size: 12px;
	font-weight: bold;
}

.menu_root {
	padding: 0;
	margin: 0 0 6px 0;
}

.req {color:red; display:inline; height:15px; width:8px;}

.req_table {
	border:1px solid #666;
}

.req_table_hd_a {
	background-color:#ddd;
	border-right:1px solid #666;
	border-bottom:1px solid #666;
	padding:4px;
	font-size:8pt;
	font-weight:bold;
}

.req_table_hd_b {
	background-color:#dddddd;
	border-bottom:1px solid #666;
	padding:4px; font-size:8pt;
	font-weight:bold;
}

.req_table_cell_a {
	border-right:1px solid #666;
	border-bottom:1px solid #666;
	padding:4px;
	font-size:8pt;
}

.req_table_cell_b {
	border-bottom:1px solid #666;
	padding:4px; font-size:8pt;
}

.req_table_cell_c {
	border-right:1px solid #666;
	padding:4px; font-size:8pt;
}

.req_table_cell_d {
	padding:4px;
	font-size:8pt;
}
.req_table ul.req_list {
	padding-left:8px;
	margin-left:8px;
}
.req_table ul.req_list li.req_bullet {
	list-style-type:square;
	list-style-image: none;
}

.left{
	float:left;
	font-weight: bold;
	width: 200px;
	margin-left: 20px;
	}
.right{
	float:right;
	}
.clear{
	clear:both;
	padding: 2px;
	}

.tablesol {
	border-collapse: collapse;
	border: none;
}

.tblsol-topl {
	background: #000 url(/images/corners/package-border-tl.gif) top left no-repeat;
}

.tblsol-topl-new {
	background-color: #000;
    background-image: url(/images/corners/package-border.gif), url(/images/corners/package-border.gif);
    background-position: top, left;
    background-repeat: repeat-x, repeat-y;
    border-left: 1px solid #000;
}

.tblsol-leftb {
	background: #b4b5b9 url(/images/corners/package-border.gif) top left repeat-y;
	 border-top: 1px solid #000;
	 border-bottom: 1px solid #000;
	 border-right: 1px solid #000;
	 padding: 5px 0;
}

.tblsol-leftb1 {
	background: #b4b5b9 url(/images/corners/package-border.gif) top left repeat-y;
	 border-top: 1px solid #000;
	 border-bottom: 2px dotted #000;
	 border-right: 1px solid #000;
	 padding: 5px 0;
}

.tblsol-leftb-new {
	background: #b4b5b9 url(/images/corners/package-border.gif)  left repeat-y;
	 /*border-top: 1px dotted #000;*/
	 border-bottom: 2px dotted #000;
	 border-right: 1px solid #000;
	 padding: 5px 0;
     background-color: #b4b5b9;
}

.tblsol-leftb2 {
	background: #b4b5b9 url(/images/corners/package-border.gif) left repeat-y;
	 /*border-top: 1px dotted #000;*/
	 border-bottom: 1px solid #000;
	 border-right: 1px solid #000;
	 padding: 5px 0;
     background-color: #b4b5b9;
}

.tblsol-rightb {
	 background: #e7e7e7 url(/images/corners/package-border.gif) top right repeat-y;
	 border-top: 1px solid #000;
	 border-bottom: 1px solid #000;
}

.rightb-wht {
	 background: #005d8c url(/images/corners/package-border.gif) top right repeat-y;
	 border-left: 1px solid #000;
	 padding: 5px;
}

.tblsol-check {
	background-color: #e7e7e7;
	border: 1px solid #000;
	padding: 3px 0 3px 8px;
}

.wht {
	background-color: #005d8c;
	border-right: 1px solid #000;
	border-left: 1px solid #000;
	padding: 5px;
}

td.blue {
	background: #000 url(/images/corners/package-border.gif) top left repeat-y;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
}

.qtipPopup {
    cursor: pointer;
}

.tblsol-pcolhead {
	color:#fff;
	border-left: 1px solid #000;
	background: #005d8c url(/images/corners/package-border.gif) top repeat-x;
	padding: 5px;
}

.tblsol-pcolhead-new {
	color:#fff;
	border-left: 1px solid #000;
    /*border-bottom: 1px solid #000;*/
/*	background: #005d8c url(/images/corners/package-border.gif) top repeat-x;*/
    background: #005d8c;
	padding: 5px;
}

.tblsol-pcolheadr {
	background: #005d8c url(/images/corners/package-border-tr.gif) top right no-repeat;
	color:#fff;
	border-left: 1px solid #000;
	padding: 5px;
}

.tblsol-pcolheadl {
    background-color: #005d8c;
    color:#fff;
	border-bottom: 1px solid #000;
	background-image: url(/images/corners/package-border.gif), url(/images/corners/package-border.gif);
    background-position: top, left;
    background-repeat: repeat-x, repeat-y;
	padding: 5px;
    border-top-left-radius: 20px;
    -moz-border-radius-topleft:20px;
    -webkit-border-top-left-radius:20px;
}

.tblsol-pcolhead-r {
    background-color: #005d8c;
    color:#fff;
	border-left: 1px solid #000;
    border-bottom: 1px solid #000;
	background-image: url(/images/corners/package-border.gif), url(/images/corners/package-border.gif);
    background-position: top, right;
    background-repeat: repeat-x, repeat-y;
	padding: 5px;
    border-top-right-radius: 20px;
}

.tblsol-pcolhead-mid {
    background: #005d8c url(/images/corners/package-border.gif) top repeat-x;
    color:#fff;
	border-bottom: 1px solid #000;
    border-left: 1px solid #000;
	padding: 5px;
}

.tblsol-pcolheadr-new {
	/*background-color: #005d8c;*/
   /* background-image: url(/images/corners/package-border.gif), url(/images/corners/package-border.gif);
    background-position: top, right;
    background-repeat: repeat-x, repeat-y;  */
   background: #005d8c url(/images/corners/package-border.gif) right repeat-y;
	color:#fff;
	border-left: 1px solid #000;
	padding: 5px;
}

div.indent {
	margin-left: 8px;
}


.ilink {
	float:right;
	clear:right;
	border: none;
	margin: 0 0 10px 15px;
}

fieldset.fieldsetForm {
	padding:0 0 10px 15px;
}



.round_box_table {
	margin-right:0;
}
.round_box_header {
	padding: 3px 10px;
	border-top: 1px solid #9fa0a2;
	cursor: pointer;
	height: 100%;
	font-weight: bold;
	text-align: center;
	font-size: 8pt;
	font-family: Verdana;
	cursor: default;
	background-color: #ccc;
}
.round_box_subheader {
	background-image:url(/images/breadcrumb_gradient_reverse.gif);
	background-repeat:repeat-x;
	border-left:1px solid #9FA0A2;
	border-right:1px solid #9FA0A2;
	padding:2px;
}
.round_box_subheader span {
	font-size:8pt;
	padding-left:5px;
	font-weight:bold;
}
.round_box_content {
	cursor: default;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	padding-left: 5px;
	text-align: left;
	font-size: 8pt;
}
div.round_box_content ul {
	 margin:0;
	 padding-left:20px;
	 text-align:left;
}
.round_box_content ul li {
	margin-bottom:5px;
}
.round_box_top_left {
	 cursor: default;
	 background-image: url(/images/tab_parts/left_default_middle.gif);
}
.round_box_top_middle {
	cursor: default;
	background-color: #D1D1D1;
	color: black;
	font-weight: bold;
	font-size:9pt;
}
.round_box_top_right {
	 cursor: default;
	 background-image: url(/images/tab_parts/right.gif);
}
round_box_table .smalltext, round_box_table tr td div .smalltext a, round_box_table .smalltext a:visited, round_box_table .smalltext a:active {
	font-size:8pt;
}

.errmsg {
 margin:3px;
 padding:5px;
 border: 2px solid #c11212;
 background-color:#f89797;
 font-weight:bold;
}
.errmsg p {
 margin: 0;
 padding: 5px 0;
}

.infomsg {
 margin:3px;
 padding:5px;
 border: 1px solid #B5B8FF;
 background-color:#D5D8FF;
}
.infomsg p {
 margin: 0;
 padding: 5px 0;
}

input.uname, input.pword {
 	width: 12em;
}

.bl_portal {
	background: 0 100% no-repeat #005ABB;
	width: 100%;
	font-size: 10px;
}
.br_portal {
	background: 100% 100% no-repeat
}
.tl_portal {
	background: url(/images/custom/tl_portal.gif) 0 0 no-repeat
}
.tr_portal {
	text-align:center;
	color: #fff;
	background: url(/images/custom/tr_portal.gif) 100% 0 no-repeat;
	padding:7px
}
.clear_portal {
	font-size: 1px;
	height: 0;
}

.lictablesol {
	border-collapse: collapse;
	border: none;
}

.lictblsol-topl {
	background: #000 url(/images/corners/package-border-tl.gif) top left no-repeat;
}
.lictblsol-leftb {
	background: #b4b5b9 url(/images/corners/package-border.gif) top left repeat-y;
	 border-top: 1px solid #000;
	 border-bottom: 1px solid #000;
	 border-right: 1px solid #000;
	 padding: 5px 0;
}

.lictblsol-rightb {
	 background: #e7e7e7 url(/images/corners/package-border.gif) top right repeat-y;
	 border-top: 1px solid #000;
	 border-bottom: 1px solid #000;
}

.licrightb-wht {
	 background: #6D4061 url(/images/corners/package-border.gif) top right repeat-y;
	 border-left: 1px solid #000;
	 padding: 5px;
}

.lictblsol-check {
	background-color: #e7e7e7;
	border: 1px solid #000;
	padding: 3px 0 3px 8px;
}

.licwht {
	background-color: #6D4061;
	border-right: 1px solid #000;
	border-left: 1px solid #000;
	padding: 5px;
}

td.licblue {
	background: #000 url(/images/corners/package-border.gif) top left repeat-y;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
}

.lictblsol-pcolhead {
	color:#fff;
	border-left: 1px solid #000;
	background: #6D4061 url(/images/corners/line_top_opaque.png) top repeat-x;
	padding: 5px;
}

.lictblsol-pcolheadr {
	background: #6D4061 url(/images/corners/corner_top_rt_opaque.png) top right no-repeat;
	color:#fff;
	border-left: 1px solid #000;
	padding: 5px;
	height: 30px;
}

.lic_table_header {
	background: grey url(/images/corners/lic-store-tl.gif) top right no-repeat;
	color: white;
	border-left: 1px solid black;
	padding: 5px;
}
.licensing_table_td {
	border-bottom: 1px solid black;
}

.thdrcell {
   background:#F3F0E7;
   font-family:arial;
   font-size:12px;
   font-weight:bold;
   padding:5px;
   border-bottom:1px solid #E4E4E4;
}

.tdatacell {
   font-family:arial;
   font-size:12px;
   padding:5px;
   background:#fff;
}

.dvhdr1 {
   background:#E4E4E4;
   font-family:arial;
   font-size:12px;
   font-weight:bold;
   border:1px solid grey;
   padding:5px;
   width:350px;
}

.dvbdy1 {
   background:white;
   font-family:arial;
   font-size:12px;
   border-left:1px solid grey;
   border-right:1px solid grey;
   border-bottom:1px solid grey;
   padding:5px;
   width:350px;
}
.licTable {
	border: 1px solid silver;
}
.TableHead {
	font: 10px arial;
	font-weight: bold;
	text-align: center;
	border-bottom: 1px solid silver;
	border-top: 1px solid silver;
	width:80px;
}
#leftColumn {
	width: 185px;
	border-left: 1px solid silver;
}
#rightColumn {
	border-right: 1px solid silver;
}
.tableContent {
	color: grey;
	text-align: center;
}
.stateHeadlines {
	font-size: 16px;
	letter-spacing: 3px;
}
#state_select_online {
	font-size: 1.15em;
	margin-top: 15px;
}
#state_select_level_online{
	font-size: 1em;
	letter-spacing: .15px;
	margin: 3px 0 2px 0;
}

div.dijitDialogPaneContent div.hide div.button {
	margin-bottom:8px;
}

div.dijitDialogPaneContent div.hide div.button a, div.dijitDialogPaneContent div.hide div.button a:active, div.dijitDialogPaneContent div.hide div.button a:visited {
	float:right;
	font-size:14px;
	font-weight:bold;
	padding:3px 5px 3px 20px;
	background-color:#E9E9E9;
	border:1px solid #ccc;
	background-image:url(/images/button_icons/arrow_back.png);
	background-repeat:no-repeat;
	background-position:6px center;
	text-decoration:none;
	margin-bottom:10px;
}
div.dijitDialogPaneContent div.hide div.button a:hover  {
	background-color:#cfcfcf;
}

table.default_rnd_callout_table tr td.default_rnd_callout_table_lt {
	background:#666 url(/images/menus/def_rnd_callout_bck_lt.gif) top left no-repeat; height:25px;
	width:15px;
}
table.default_rnd_callout_table tr td.default_rnd_callout_table_bck {
	background:#666 url(/images/menus/def_rnd_callout_bck.gif) top left repeat-x; height:25px;
	font-family:Arial, Helvetica, sans-serif;
	color:#fff;
	font-weight:bold;
	font-size:12px;
	padding-top:6px;
}
table.default_rnd_callout_table tr td.default_rnd_callout_table_rt {
	background:#666 url(/images/menus/def_rnd_callout_bck_rt.gif) top right no-repeat; height:25px;
	width:15px;
}

table.default_rnd_callout_table_grey tr td {
	background-color:#e6e6e6;
	border-left:1px solid #666;
	border-bottom:1px solid #666;
	font-weight:bold;
	font-size:8pt;
}

td.default_rnd_callout_table_grey_rt {
	background-color:#e6e6e6;
	border-left:1px solid #666;
	border-right:1px solid #666;
	border-bottom:1px solid #666;
	font-weight:bold;
}

td.default_rnd_callout_table_modules {
	width: 100px;
}

div.map_container {
	border:1px solid #c2c2c2;
	padding:0;
}

div.map_container div.corner_left_top {
	background-image:url(/images/corners/map_corner_topleft.png);
	background-repeat:no-repeat;
	background-position:left top;
	margin-top:-1px;
	margin-left:-1px;
	width:15px;
	height:15px;
	float:left;
}


div.map_container div.corner_right_top {
	background-image:url(/images/corners/map_corner_topright.png);
	background-repeat:no-repeat;
	background-position:right top;
	margin-top:-1px;
	margin-right:-1px;
	width:15px;
	height:15px;
	float:right;
	position:relative;
}

div.map_container div.corner_left_bottom {
	background-image:url(/images/corners/map_corner_bottomleft.png);
	background-repeat:no-repeat;
	background-position:left bottom;
	margin-top:-14px;
	margin-left:-1px;
	width:15px;
	height:15px;
	float:left;
}

div.map_container div.corner_right_bottom {
	background-image:url(/images/corners/map_corner_bottomright.png);
	background-repeat:no-repeat;
	background-position:right bottom;
	margin-top:-14px;
	margin-right:-1px;
	width:15px;
	height:15px;
	float:right;
}

div.state_select_dropdown {
	float:right;
	margin-top:-1px;
	margin-right:-15px;
	font-weight:bold;
	border:1px solid #c2c2c2;
	padding:5px 10px 1px 0;
	background-color: #e4e4e4;
}
div.state_select_dropdown a#select_state {
	text-decoration:none;
	padding:6px;
}
div.state_select_dropdown span.state_text {

	position:relative;
	top:-3px;
}

div.state_list {
	position:relative;
	float:right;
	background-color:#fff;
	margin-top:26px;
	margin-left:-601px;
	width:500px;
	border:1px solid #c2c2c2;
	padding:10px 0;
}
html>/**/body div.state_list {
	margin-left:-501px;
	float:right;
}

div.state_list ul {
	margin:0 10px;
	padding:0;
}
div.state_list ul li {
	list-style:none;
	margin:0 0 4px 0;
	padding:0;
}
div.state_list ul.state_list_item {
	float:left;
	margin-right:38px;
}
div.state_list ul.state_list_item_last {
	float:left;
	margin-right:10px;
}

table.roundbox_table {
	float:right;
	margin:10px 0 0 15px;
	width:240px;
}

table.roundbox_table tr td.roundbox_table_header_lt {
	background:url(/images/corners/roundbox_header_lft.png) top left no-repeat;
	height:26px;
	background-color:#6d4061;
	width:15px;
}

table.roundbox_table tr td.roundbox_table_header_bck {
	background:url(/images/corners/roundbox_header_bck.png) top left repeat-x;
	background-color:#6d4061;
	font-family:Arial, Helvetica, sans-serif;
	font-weight:bold;
	font-size:12px;
	color:#fff;
	padding-top:0;
	height:20px;
}

table.roundbox_table tr td.roundbox_table_header_rt {
	background:url(/images/corners/roundbox_header_rt.png) top right no-repeat;
	height:26px;
	background-color:#6d4061;
	width:15px;
}

table.roundbox_table tr td.roundbox_table_btm_lt {
	background:url(/images/corners/roundbox_lt.gif) bottom left no-repeat; height:31px;
	width:15px;
	height:5px;
}

table.roundbox_table tr td.roundbox_table_btm_bck {
	background:#e4e4e4;
	padding:0;
	margin:0;
	line-height:3px;
	height:5px;
	font-family:Arial, Helvetica, sans-serif;
	color:#fff;
	font-size:3px;
	font-weight:bold;
	border-bottom:1px solid #8f8f8f;
}

table.roundbox_table tr td.roundbox_table_btm_rt {
	background:url(/images/corners/roundbox_rt.gif) bottom right no-repeat; height:31px;
	width:15px;
	height:5px;
}

table.roundbox_table tr td.roundbox_table_grey {
	border-left:1px solid #8f8f8f;
	border-right:1px solid #8f8f8f;
	background-image:url(/images/corners/roundbox_content_bck.gif);
	background-position:bottom;
	background-repeat:repeat-x;
	font-weight:bold;
	font-size:8pt;
	padding:10px 10px 0 10px;
}
table.roundbox_table tr td.roundbox_table_grey ul {
	font-weight:normal;
	margin:0;
	margin-left:17px;
	padding:0;
}

table.prod_table {
	float:right;
	margin:10px 0 0 0;
	width:100%;
}

table.prod_table td.purp_head {
	padding:0;
}

table.prod_table tr td div.prod_table_header_back {
	background:url(/images/corners/roundbox_header_bck.png) top left repeat-x;
	background-color:#6d4061;
	font-family:Arial, Helvetica, sans-serif;
	font-weight:bold;
	font-size:12px;
	color:#fff;
	padding-top:5px;
	height:23px;
	width:100%;
	float:left;
}

table.prod_table tr td div.prod_table_header_lt {
	background:url(/images/corners/roundbox_header_lft.png) top left no-repeat;
	height:28px;
	background-color:#6d4061;
	width:12px;
	float:left;
	margin-top:-5px;
}

table.prod_table tr td div.prod_table_header_rt {
	background:url(/images/corners/roundbox_header_rt.png) no-repeat;
	background-position:top right;
	height:28px;
	background-color:#6d4061;
	width:12px;
	float:right;
	margin-top:-5px;
}

table.prod_table tr td {

	padding:4px 3px 6px 10px;
}

table.prod_table tr td.prod_table_left {

	border-left:1px solid #c2c2c2;
}

table.prod_table tr td.prod_table_right {

	border-right:1px solid #c2c2c2;
}

table.prod_table tr.prod_col_header td {
	background-color:#eaeaea;
	font-weight:bold;
	padding-top:3px;
	padding-bottom:3px;
	border-bottom:1px solid #c2c2c2;
}

table.prod_table tr td.price {
	font-weight:bold;
}

div.state_select_wrap {
	float:right;
	width:183px;
}

a.go_btn, a.go_btn:active, a.go_btn:visited {
	float:right;
	border:0;
	margin-top:1px;
	font-weight:bold;
	font-size:12px;
	background-image:url(/images/buttons/go_off.gif);
	background-repeat:no-repeat;
	background-position:top left;
	width:20px;
	height:17px;
	text-decoration:none;
	padding:1px 0 0 6px;
}
a.go_btn:hover {
	font-weight:bold;
	background-image:;
}

table.prod_table div.state {
	float:left;
}

table.prod_table div.license {
	float:left;
}

div.select_dropdown {
	margin-top:1px;
	margin-right:0;
	border:0;
	padding:1px 0 0 0;
	width:143px;
	height:17px;
	float:left;
	position:relative;
}

div.select_dropdown_lft {
	background-image:url(/images/buttons/dropdown_left.gif);
	background-repeat:no-repeat;
	background-position:left top;
	width:5px;
	height:17px;
	margin-top:-1px;
	margin-right:0;
	float:left;
}
div.select_dropdown_rt {
	background-image:url(/images/buttons/dropdown_off.gif);
	background-repeat:no-repeat;
	background-position:right top;
	width:22px;
	height:17px;
	margin-top:-1px;
	float:right;
}

div.select_dropdown a#select_state {
	text-decoration:none;
	padding:1px 0 2px 0;
	margin-left:0;
	margin-top:-1px;
	font-size:11px;
	font-weight:normal;
	background-image:url(/images/buttons/dropdown_bck.gif);
	background-repeat:repeat-x;
	height:17px;
	width:116px;
	float:left;
}
div.select_dropdown a#select_license {
	text-decoration:none;
	padding:1px 0 2px 0;
	margin-left:0;
	margin-top:-1px;
	font-size:11px;
	font-weight:normal;
	background-image:url(/images/buttons/dropdown_bck.gif);
	background-repeat:repeat-x;
	height:17px;
	width:116px;
	float:left;
}
div.sortbyDrop {
	text-decoration:none;
	padding:1px 0 2px 0;
	margin-left:0;
	margin-top:-1px;
	font-size:11px;
	font-weight:normal;
	background-image:url(/images/buttons/dropdown_bck.gif);
	background-repeat:repeat-x;
	height:17px;
	float:left;
	position:relative;
	left:0;
	z-index: 10001;
}

.sortbyDrop2 {
	text-decoration:none;
	padding:1px 0 2px 0;
	margin-left:0;
	margin-top:-1px;
	font-size:11px;
	font-weight:normal;
	background-image:url(/images/buttons/dropdown_bck.gif);
	background-repeat:repeat-x;
	height:17px;
	width: 86px;
	float:left;
	z-index: 10001;

}

div.state_list {
	position:absolute;
	float:left;
	background-color:#fff;
	margin-top:18px;
	margin-left:-110px;
	width:500px;
	margin-right:0;
	border:1px solid #c2c2c2;
	padding:10px 0;
	z-index: 10000;
}
html>/**/body div.state_list {
	margin-left:31px;
	margin-top:17px;
	float:right;
}

div.license_list {
	position:absolute;
	float:left;
	clear:left;
	background-color:#fff;
	margin-top:-2px;
	margin-left:0;
	width:143px;
	margin-right:22px;
	border:1px solid #c2c2c2;
	padding:10px 0;
	z-index: 10000;
}
html>/**/body div.license_list {
	margin-top:17px;
	width:141px;
}
div.license_list ul {
	margin:0 10px;
	padding:0;
}
div.license_list ul li {
	list-style:none;
	margin:0 0 4px 0;
	padding:0;
}
div.license_list ul.license_list_item {
	float:left;
	width:84%;
}
div.license_list ul.license_list_item li {
	font-size:11px;
}
div.license_list ul.license_list_item_last {
	float:left;
	margin-right:10px;
}

div.sort_list {
	position:absolute;
	background-color:#fff;
	margin:-3px 0 0 0;
	width:113px;
	border:1px solid #c2c2c2;
	padding:7px 0 5px 3px;
	z-index: 10000;
}

html>/**/body div.sort_list {
	position:absolute;
	background-color:#fff;
	margin:15px 0 0 0;
	width:108px;
	border:1px solid #c2c2c2;
	padding:7px 0 5px 3px;
}

div.sort_list ul {
	margin:0 10px;
	padding:0;
	font-size:11px;
}
div.sort_list ul li {
	list-style:none;
	margin:0 0 4px 0;
	padding:0;
}

table.legend {
	border-top:1px solid #c2c2c2;
	border-left:1px solid #c2c2c2;
	border-right:1px solid #c2c2c2;
	font-size:11px;
	margin-top:16px;
}

table.legend tr td tr td {
	font-size:11px;
	border-bottom:0;
	padding-bottom:1px;
	padding-top:0;
}

tr.legend_icons td img {
	margin-bottom:-3px;
	margin-top:5px;
	padding-top:0;
}
tr.legend_icons td a {

}

ul.license_type_list {
	margin:0;
	margin-left:12px;
	padding:0;
}

ul.license_type_list li {
	margin:0;
	padding:0;
	list-style:url(/images/bullet2.gif);
}

table.grey_table {
	float:right;
	margin:10px 0 0 15px;
	width:100%;
}

table.grey_table td.grey_head {
	padding:0;
}

table.grey_table tr td div.grey_table_header_back {
	background:url(/images/menus/grey_grad_header.gif) top left repeat-x;
	background-color:#d4d4d4;
	font-family:Arial, Helvetica, sans-serif;
	font-weight:bold;
	font-size:12px;
	color:#fff;
	padding-top:5px;
	height:25px;
	width:100%;
	float:left;
	border-top:1px solid #ababab;
	border-bottom:1px solid #ababab;
}

table.grey_table tr td div.grey_table_header_lt {
	background:url(/images/menus/greybox_header_lft.gif) top left no-repeat;
	height:32px;
	background-color:#d4d4d4;
	width:12px;
	float:left;
	margin-top:-6px;
}

table.grey_table tr td div.grey_table_header_rt {
	background:url(/images/menus/greybox_header_rt.gif) no-repeat;
	background-position:top right;
	height:32px;
	background-color:#d4d4d4;
	width:12px;
	float:right;
	margin-top:-6px;
}

table.grey_table tr td {

	padding:4px 3px 6px 10px;
}

table.grey_table div.state {
	float:left;
}

table.grey_table div.license {
	float:left;
}

table.greytable div.select_dropdown {
	margin-top: 2px;
	margin-right: 0;
	border: 0;
	padding: 0;
	width: 143px;
	height: 17px;
}

table.grey_table div.select_dropdown_lft {
	background-image:url(/images/buttons/dropdown_left.gif);
	background-repeat:no-repeat;
	background-position:left top;
	width:5px;
	height:17px;
	margin-top:1px;
	margin-right:0;
	float:left;
}
table.grey_table div.select_dropdown_rt {
	background-image:url(/images/buttons/dropdown_off.gif);
	background-repeat:no-repeat;
	background-position:right top;
	width:22px;
	height:17px;
	margin-top:1px;
	float:right;
}

table.grey_table div.select_dropdown a#select_state {
	text-decoration:none;
	padding:1px 0 2px 0;
	margin-left:0;
	margin-top:1px;
	font-size:11px;
	font-weight:normal;
	background-image:url(/images/buttons/dropdown_bck.gif);
	background-repeat:repeat-x;
	height:17px;
	width:116px;
	float:left;
}
table.grey_table div.select_dropdown a#select_license {
	text-decoration:none;
	padding:1px 0 2px 0;
	margin-left:0;
	margin-top:1px;
	font-size:11px;
	font-weight:normal;
	background-image:url(/images/buttons/dropdown_bck.gif);
	background-repeat:repeat-x;
	height:17px;
	width:116px;
	float:left;
}

table.grey_table div.state_list {
	position:absolute;
	float:right;
	background-color:#fff;
	margin-top:23px;
	margin-left:40px;
	width:500px;
	margin-right:22px;
	border:1px solid #c2c2c2;
	padding:10px 0;
}

table.grey_table div.license_list {
	position:absolute;
	float:left;
	background-color:#fff;
	margin-top:23px;
	margin-left:12px;
	width:142px;
	margin-right:22px;
	border:1px solid #c2c2c2;
	padding:10px 0;
}
table.grey_table div.license_list ul {
	margin:0 10px;
	padding:0;
}
table.grey_table div.license_list ul li {
	list-style:none;
	margin:0 0 4px 0;
	padding:0;
}
table.grey_table div.license_list ul.license_list_item {
	float:left;
	margin-right:38px;
}
table.grey_table div.license_list ul.license_list_item_last {
	float:left;
	margin-right:10px;
}

.bg_shadow {
	background-image:url(/images/menus/prod_table_shadow.gif);
	background-position:top;
	background-repeat:repeat-x;
}

.table_bg_shadow {
	height:8px;
	width:100%;
	margin-left:-4px;
	margin-top:-4px;
	margin-bottom:-10px;
	position:relative;
}
html>/**/body .table_bg_shadow {
	margin-bottom:-4px;
}
#detailsButton {
	cursor: pointer;
	float: left;
	width: 56px;
	height: 18px;
	padding: 2px 0 0 0;
	margin-top:5px;
	margin-bottom:0;
	background: white url('/images/buttons/grey_button.gif') no-repeat;
	font-size: 10px;
}
#detailsButton:hover {
	background: white url('/images/buttons/grey_button_pushed.gif') no-repeat;
}
#detailsButton span a {
	padding:0;
	margin:2px 2px 2px 7px;
}

#detailedDescButton {
	cursor: pointer;
	float: left;
	width: 56px;
	height: 18px;
	padding: 2px 0 0 0;
	margin-top:5px;
	margin-bottom:0;
	background: white url('/images/buttons/grey_button.gif') no-repeat;
	font-size: 10px;
}
#detailedDescButton:hover {
	background: white url('/images/buttons/grey_button_pushed.gif') no-repeat;
}
#detailedDescButton span a {
	padding:0;
	margin:2px 2px 2px 7px;
}

#classesButton {
	float: right;
	cursor: pointer;
	width: 104px;
	height: 18px;
	padding: 2px 0 0 0;
	margin-top:5px;
	margin-bottom:0;
	background: white url('/images/buttons/date_location_button.gif') no-repeat;
	font-size: 10px;
}
#classesButton:hover {
	background: white url('/images/buttons/date_location_button_on.gif') no-repeat;
}
#classesButton span a {
	padding:0;
	margin:2px 2px 2px 7px;
}

#sortByMenu {
	float:right;
	position:relative;
	z-index: 900;
	width: 113px;
	margin:0 15px -28px 0;
	padding:2px 0 0 0;
}

ul.icons_list {
	float:left;
	width:33%;
	margin:0;
	padding:0;
}
li.icon_lists_wrap {
	float:left;
	width:100%;
	display:block;
	margin:0;
	list-style:none;
	clear:left;
}

div.state_list_home {
	position:absolute;
	float:left;
	background-color:#fff;
	margin-top:26px;
	margin-left:71px;
	width:500px;
	margin-right:0;
	border:1px solid #c2c2c2;
	padding:10px 0;
	z-index: 10000;
}
html>/**/body div.state_list_home {
	margin-left:69px;
	margin-top:26px;
	float:right;
}

div.state_list_home ul {
	margin:0 10px;
	padding:0;
}
div.state_list_home ul li {
	list-style:none;
	margin:0 0 4px 0;
	padding:0;
}
div.state_list_home ul.state_list_item {
	float:left;
	margin-right:38px;
}
div.state_list_home ul.state_list_item_last {
	float:left;
	margin-right:10px;
}

.state_selection_popup {
    margin: 16px;
    float: left;
    width: 600px;
}

.state_selection_column {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 200px;
}

.state_selection_column a {
    display: block;
    float: left;
    clear: both;
}

form.warning {
	margin:0;
	padding-bottom:8px;
}
html>/**/body form.warning {
	margin-bottom:8px;
}
form.warning input {
	margin-top:0;
	margin-bottom:0;
}
.exam_launch {
	border:1px solid #999;
	background-color:#ddd;
	text-decoration:none;
	color:#333;
	font-size:11px;
	font-weight:bold;
	background-image:url('/images/button_icons/accept.png');
	background-position:4px 4px;
	background-repeat:no-repeat;
	padding:3px 0 3px 11px;
	margin:5px 0 -10px 5px;
}
html>/**/body .exam_launch {
	padding:3px 2px 3px 20px;
	margin:10px 0 -10px 5px;
}
.exam_cancel {
	border:1px solid #999;
	background-color:#ddd;
	text-decoration:none;
	color:#333;
	font-size:11px;
	font-weight:bold;
	background-image:url('/images/button_icons/cancel.png');
	background-position:4px 4px;
	background-repeat:no-repeat;
	padding:3px 0 3px 11px;
	margin:5px 7px -10px 0;
}
html>/**/body .exam_cancel {
	padding:3px 2px 3px 20px;
	margin:10px 7px -10px 0;
}

.exam_cancel:hover {
	background-color:#bbb;
	cursor:pointer;
}

#notMeetRequirements {
	background-color:#fff;
	display:none;
	width:50%;
}

.purp_head_text {
	position:relative;
	top: 3px;
	z-index: 1;
	float:left;
}

.grey_buttons_wrapper {
	font-size:10px;
	width: 170px;
	text-decoration:none;
}
.grey_buttons_wrapper a {
	font-size:10px;
	text-decoration:none;
}
.grey_buttons_wrapper a:active {
	font-size:10px;
	text-decoration:none;
}
.grey_buttons_wrapper a:visited {
	font-size:10px;
	text-decoration:none;
}

table#packageTable td.prod_icons_col {
	width:220px;
	padding-left:0;
	padding-right:0;
}

table#packageTable td.packagePrice {
	text-align: center;
	width:8%;
}

#buy_now_col {
	width:18%;
	padding-right:9px;
}

#details_container {
	padding: 0;
	margin: 0;
}

.details_box {
	background-color:#f7f7f7;
	border:1px solid #ccc;
	position:relative;
	left: 0;
	width: 529px;
	padding:0;
	margin-left:10px;
}

.dates_locations_wrapper {
	width: 529px;
	overflow: hidden;
	margin-left:10px;
}

.dates_locations_box {
	background-color:#f7f7f7;
	border:1px solid #ccc;
	padding-top:5px;
	padding-bottom:5px;
	position:relative;
	left:0;
	margin-bottom:5px;
}

.dates_locations_table {
	width:505px;
}
.dates_locations_table div {
	width:505px;
}


ul.icons {
	padding-left:0;
	margin-left:3%;
	padding-bottom:0;
	margin-bottom:0;
	list-style:none;
	text-align:left;
}

ul.icons li.pdf_icon {
	background-image:url(/images/button_icons/pdf.gif);
	background-repeat:no-repeat;
	background-position:left top;
	height:26px;
	padding-left:24px;
	padding-bottom:8px;
}

ul.icons li.pdf_icon a {
    font-size: 8pt;
    font-weight: normal;
}

ul.icons li.video_icon {
	background-image:url(/images/button_icons/control_play_blue.png);
	background-repeat:no-repeat;
	background-position:left top;
	height:26px;
	padding-left:24px;
}

.dialog_close {
	float:right;
	margin-bottom:4px;
	clear:left;
}

#auth_access_info {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9pt;
}

.view_req {
	width:465px;
	margin-top:-15px;
}
html>/**/body .view_req {
	margin-top:0;
}

.lic_pleasenote ul {
	margin:5px 0 5px 17px;
	padding:0;
}

div.homepage_flash_video {
	float:right;
	width:279px;
	height:200px;
	margin:3px 0 15px 15px;
	padding:0;
}
#showVideo_licensing {
    width:279px;
    height:180px;
    cursor: pointer;
}

form.addon_form {
	width:100%;
	padding:0;
	margin:0;
}
form.addon_form input {
	margin:0;
}
form.addon_form div.addon_name {
	float:left;
	width:420px;
	display:block;
}
div.addon_price {
	float:right;
	width:70px;
	display:block;
	text-align:center;
}
html>/**/body div.addon_price {
	width:88px;
}
div.addon_button_wrapper {
	width:160px;
	margin-left:auto;
	margin-right:auto;
}
input.addon_button {
	border:1px solid #999;
	background-color:#ddd;
	text-decoration:none;
	color:#333;
	font-size:11px;
	font-weight:bold;
	background-image:url('/images/button_icons/cart_go.png');
	background-position:4px 4px;
	background-repeat:no-repeat;
	padding:5px 0 4px 20px;
	margin-top:15px;
	margin-bottom:-10px;
	float:right;
	height:28px;
	width:90px;
}
html>/**/body input.addon_button {
	padding:5px 2px 4px 20px;
	margin-top:0;
	margin-bottom:-10px;
	height:auto;
	width:auto;
}

table.prod_table td.addon_table {
	padding:0;
	margin:0;
}
div.addon_wrap {
	padding:10px;
	text-align:left;
	border:1px solid #c2c2c2;
	border-top:0;
	margin-bottom:12px;
}

table#packageTable td.package_name {
	width: 200px;
}

table#packageTable td.package_name_qbank {
	width:214px;
}

#system_req_holder {
	width:100% !important;
	float:left;
	margin:10px 0;
	border:1px solid #000;
}
.system_req_box {
	width:100% !important;
	float:left;
	overflow:hidden;
}
ul.requirements {
	padding:28px 5px 5px 20px;
	#padding:8px 5px 5px 20px;
	margin:0;
}
.grey_header {
	width:100%;
	float:left;
	background:#e4e4e4;
	font-weight:bold;
	height:22px;
	line-height:22px;
}
.grey_header_padding {
	margin-left:5px;
}
.left_column_box {
	width:33%;
	float:left;
}
.middle_column_box {
	width:34%;
	float:left;
	border-left:1px solid #000;
	margin-left:-2px;
	padding-bottom:2000px;
	margin-bottom:-2000px;
}
.right_column_box {
	width:33%;
	float:right;
	border-left:1px solid #000;
	margin-left:-10px;
	padding-bottom:2000px;
	margin-bottom:-2000px;
}

table.creditTable {
	border: 1px solid black;
}



div.state_select_dropdown {
	float:right;
	margin-top:-1px;
	margin-right:-15px;
	font-weight:bold;
	border:1px solid #c2c2c2;
	padding:5px 10px 1px 0;
}
div.state_select_dropdown a#select_state {
	text-decoration:none;
	padding:6px;
}

div.state_list {
	position:relative;
	float:right;
	background-color:#fff;
	margin-top:26px;
	margin-left:-501px;
	width:500px;
	border:1px solid #c2c2c2;
	padding:10px 0;
}
div.state_list ul {
	margin:0 10px;
	padding:0;
}
div.state_list ul li {
	list-style:none;
	margin:0 0 4px 0;
	padding:0;
}
div.state_list ul.state_list_item {
	float:left;
	margin-right:38px;
}
div.state_list ul.state_list_item_last {
	float:left;
	margin-right:10px;
}

div.graybx_large_gradient {
	background:url(/images/corners/graybx_large_gradient.gif) bottom left repeat-x;
	margin-top:0;
	padding-top:0;
}
table.graybx_large_half {
	width:100%;
	background:url(/images/boxes/box_green_stripes.png) top right no-repeat;
}
table.graybx_large_half div.box_header {
	text-align:center;
	font-weight:bold;
	border-bottom:1px solid #a8a8a8;
	padding-bottom:8px;
}
table.graybx_large_half div.box_content {
	padding:0 10px;
}
table.graybx_large_half ul.pad_list {
	font-size:10pt;
	margin-top:5px;
	margin-bottom:5px;
}
table.graybx_large_half td.tl {
	background:url(/images/corners/graybx_cnr_tl.gif) top left no-repeat;
	width:10px;
	font-size:1px;
	line-height:1px;
	height:8px;
}
table.graybx_large_half td.tbck {
	border-top:1px solid #a8a8a8;
	font-size:1px;
	line-height:1px;
	height:8px;
	width:230px;
}
table.graybx_large_half td.tr {
	background:url(/images/corners/graybx_cnr_tr.gif) top right no-repeat;
	width:10px;
	font-size:1px;
	line-height:1px;
	height:8px;
}
table.graybx_large_half td.content {
	border-left:1px solid #a8a8a8;
	border-right:1px solid #a8a8a8;
}
table.graybx_large_half td.bl {
	margin-top:1px;
	background:url(/images/corners/graybx_cnr_bl.gif) bottom left no-repeat;
	width:10px;
	font-size:1px;
	line-height:1px;
	height:8px;
}
table.graybx_large_half td.bbck {
	border-bottom:1px solid #a8a8a8;
	font-size:1px;
	line-height:1px;
	height:8px;
	width:230px;
}
table.graybx_large_half td.br {
	background:url(/images/corners/graybx_cnr_br.gif) bottom right no-repeat;
	width:10px;
	font-size:1px;
	line-height:1px;
	height:8px;
}


/*
    Table used for styling portal contact
*/
table.contactInfo {
    border: 1px solid #000;
    margin: 0 0 15px;
}
table.contactInfo caption {
    text-align: left;
    font-weight: bold;
    border-bottom: 2px solid #1D2071;
    margin: 10px 0 10px;
}
table.contactInfo td,
table.contactInfo th {
    padding: 5px;
    text-align: left;
    vertical-align: top;
    border-left: 1px solid #000;
}
table.contactInfo th:first-child,
table.contactInfo td:first-child{
    border-left: none;
}
table.contactInfo th {
    background-color: #CCC;
    border-bottom: 1px solid #000;
}


/*
    Table used to align phone numbers and their descriptions
*/
table.phoneInfo {
    border: none;
}
table.phoneInfo td,
table.phoneInfo th {
    padding: 0px;
    border: none;
}
table.phoneInfo th {
    background-color: transparent;
    font-weight: bold;
    padding-right: 10px;
}


div.ce_home_banner {
	background:url(/images/box_headings/ins_ce_banner.jpg) top left no-repeat;
	width:566px;
	height:121px;
}

SELECT.miles_select {
	font-size:12px;
}
INPUT.zip_code_input {
	border:1px solid #bbb;
	font-size:12px;
	padding-left:3px;
}

div.ce_submit_button {
	background:#FFF url(/images/buttons/grey_button.gif) top left no-repeat;
	font-size:10px;
	width:56px;
	height:18px;
	border:0;
	font-weight:bold;
	margin-top:6px;
}
div.ce_submit_button:hover {
	background:#FFF url(/images/buttons/grey_button_pushed.gif) top left no-repeat;
}

div.banner_title_3 {
	padding-left:185px;
	font-size:12px;
	padding-top:22px;
}
html>/**/body div.banner_title_3 {
	padding-left:185px;
	font-size:12px;
	padding-top:20px;
}

.price_tag_img {
	float:right;
	margin:-7px -13px -10px -4px;
	position:relative;
}

html>/**/body .price_tag_img {
	float:right;
	margin:-7px -10px -5px 0;
	position:relative;
}
.float_left {
    float:left;
    width:95%;
}

#class_documents_box {
	width:95%;
	border:1px solid #000;
	float:left;
	overflow:hidden;
	margin-bottom:10px;
}

#course_outline_box {
	width:50%;
	float:left;
}

.icon_list_padding {
    margin-top:30px;
    #margin-top:15px;
}

#supplemental_docs_box {
	width:50%;
	float:right;
	border-left:1px solid #000;
	margin-left:-2px;
	padding-bottom:2000px;
	margin-bottom:-2000px;
}

.clearfix:after  {
    content:".";
    display:block;
    height:0;
    clear:both;
    visibility:hidden;
}

.clearfix  {
	display:inline-block;
}

/* Hide from IE Mac \*/
.clearfix  {
	display:block;
}
/* End hide from IE Mac */

#upcoming_events_box {
	width:95%;
	border:1px solid #000;
	float:left;
	overflow:hidden;
	margin:10px 0;
}

.center_text {
	text-align:center;
}

.left_text {
	text-align:left;
}


div.product_avail_head h3 a.collapsed,
div.product_avail_head p a.collapsed {
	background: url(/images/menus/arrow_collapsed.gif) center left no-repeat;
	display: block;
	padding: 0 0 0 13px;
}

div.product_avail_head h3 a.collapsed:hover {
	background: url(/images/menus/arrow_collapsed.gif) center left no-repeat;
}

div.product_avail_head h3 a.expanded,
div.product_avail_head h3 a.expanded:active,
div.product_avail_head h3 a.expanded:visited {
	text-decoration: none;
	display: block;
	padding: 0 0 0 13px;
	background: url(/images/menus/arrow_expand.gif) center left no-repeat;
}

div.product_avail_head h3 a.expanded:hover {
	background: url(/images/menus/arrow_expand.gif) center left no-repeat;
}

.small_h3 {
	font-size:12px;
}

a.hp_collapsed, a.hp_collapsed:active, a.hp_collapsed:visited {
	text-decoration: none;
	display: block;
	color: #555;
	font-size: 12px;
	font-weight: bold;
	padding: 2px 10px 2px 5px;
	background: url(/images/menus/plus.gif) right top no-repeat;
}

a.hp_collapsed:hover {
	color: #777;
	background: url(/images/menus/plus_on.gif) right top no-repeat;
}

div.product_avail_box {
	padding: 0 20px;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	font-weight: normal;
}

.pad_session_text {
    margin:0 10px 10px 10px;
    float:left;
}

.bordered_box {
    float:left;
    border-bottom:1px solid #000;
    width:100%;
}

.non_bordered_box {
    float:left;
    width:100%;
    padding:6px 0 6px 0;
}

.bordered_box_padding {
	padding-left:5px;
}

.class_box_padding {
    float:left;
    width:100%;
    padding:6px 0 6px 0;
}

.session_box_text {
    width:33%;
    float:left;
    margin-top:10px;
}

.start_box_text {
    width:33%;
    float:left;
    text-align:center;
    margin-top:10px;
    margin-bottom:10px;
}

.handouts_box_text {
    width:30%;
    float:left;
    text-align:center;
}

.event_row_box {
    width:100%;
    float:left;
}

.border {
	border-bottom:1px solid #000;
}

hr.divider {
  border: 1px solid #ccc;
  clear: both;
  margin: 5px 0;
  height: 0;
}

.pad_start_text {
	margin-right:10px;
    float:left;
}

.icon_pad_for_ie {
	#margin-top:10px;
}

.float_left {
	float:left;
}

.float_right {
	float:right;
}

.noticemsg {
	background-color:#ffdd93;
	border:2px solid #f8b016;
	font-weight:bold;
	margin:3px;
	padding:5px;
}
.noticemsg p {
	margin:0;
	padding:0;
}

.st {
	font-weight: bold;
}

.pad_bot_2m {
	padding-bottom: 2em;
}

.formLabels {
	text-align: left;
	font-weight: bold;
}

.data_guide {
	font-style: italic;
	color: #888;
}

input.user_input {
	color: #333;
}

table.plain_table th {
	border-bottom: 1px solid #333;
	font-weight: bold;
	background-color: #fff !important;
}

table.plain_table.recampus th {
	border-bottom: 1px solid #333;
	font-weight: bold;
	background-color: transparent !important;
}

table.plain_table tr.plain {
	background-color: #fff;
}

table.plain_table tr.plain.recampus {
	background-color: transparent;
}

table.plain_table tr.alt {
	background-color: #eee;
}

.plain_table.pdann {
    margin: 12px 0;
}

table.list2 tr td {
	background-color: #fff;
    border-top: 1px dashed #bbb;
    padding: 12px 4px;
}

table.list2 tr td a {
    text-decoration: none;
    font-weight: bold;
}

table.list2 tr td a img {
    border: none;
}

table.schweser_style {
	width: 100%;
	margin-bottom: 14px;
}

table.schweser_style tr.top {
	background:url(/images/schweser/round_box_header_bg.gif) repeat-x scroll center top #f5f5f5;
}

table.schweser_style tr.top td {
	border: 1px solid #c6c6c6;
	border-left: none;
	border-right: none;
	color:#353535;
	font-weight:bold;
	font-family:Arial,Helvetica,sans-serif;
	font-size:9pt;
}

table.schweser_style tr.top td.corner {
    float: none;
	width: 15px;
	height: 28px;
	border: none;
	border-bottom:1px solid #c6c6c6;
	padding: 0;
	margin: 0;
	overflow:hidden;
}

table.schweser_style tr.top td.corner.left {
    background: url(/images/schweser/corner_tl.png) no-repeat scroll left top transparent;
}

table.schweser_style tr.top td.corner.right {
    background: url(/images/schweser/corner_tr.png) no-repeat scroll right top transparent;
}

table.schweser_style tr.top td.title {
    width: 275px;
}

table.schweser_style tr.top td.price {
    text-align: center;
    width: 75px;
}

table.schweser_style tr.top td.package_title {
    text-align: center;
}

table.schweser_style tr.top td.purchase {
    text-align: center;
    padding-left: 15px;
}


table.schweser_style tr.dates td {
    float: none;
    background: #eee;
    height: 28px;
    line-height: 28px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 8pt;
}

table.schweser_style tr.dates td.left {
    border-left:1px solid #c6c6c6;
    width: 15px;
}

table.schweser_style tr.dates td.right {
    border-right:1px solid #c6c6c6;
}

table.schweser_style td.description {
    border-left: 1px solid #c6c6c6;
    border-right: 1px solid #c6c6c6;
    padding: 10px;
}

table.schweser_style tr.bottom td.package_title {
    text-align: center;
}

table.schweser_style tr.content td {
    float: none;
    border-right:1px solid #c6c6c6;
    padding-top: 10px;
    color:#000;
    font-family:Arial,Helvetica,sans-serif;
    font-size:8pt;
}

table.schweser_style tr.content td.title {
    padding-left: 15px;
    border-left: 1px solid #c6c6c6;
}

table.schweser_style tr.content td.price, table.schweser_style tr.content td.purchase {
    text-align: center;
}

table.schweser_style tr.content td.dates, table.schweser_style tr.content td.files {
    border: none;
    width: 290px;
    font-weight: bold;
    padding: 0;
    height: 30px;
    overflow: hidden;
}

table.schweser_style tr.content td.files {
    padding-top: 12px;
}

table.schweser_style tr.content td.left, table.schweser_style tr.content td.right {
    width: 15px;
    padding: 0;
    float: none;
    border: none;
}

table.schweser_style tr.content td.left {
	border-left: 1px solid #c6c6c6;
}

table.schweser_style tr.content td.right {
    border-right: 1px solid #c6c6c6;
}


form.purchase_form {
    margin:0;
    padding:0;
}

table.schweser_style tr.bottom td {
    float: none;
	border-bottom: 1px solid #c6c6c6;
	border-right: 1px solid #c6c6c6;
	height: 10px;
	padding: 0;
	margin: 0;
	overflow:hidden;
}

table.schweser_style tr.bottom td.corner {
	width: 15px;
	border: none;
}

table.schweser_style tr.bottom td.corner.left {
    float: none;
    background: url(/images/schweser/corner_bl.png) no-repeat scroll left bottom transparent;
}

table.schweser_style tr.bottom td.corner.right {
    float: none;
    background: url(/images/schweser/corner_br.png) no-repeat scroll right bottom transparent;
}

table.schweser_style tr td.no_right_border {
    border-right: none;
}

table.schweser_style tr td.left_edge {
    border-left: 1px solid #c6c6c6;
    border-right: none;
}

td.state_dropdown_row {
    padding-left: 15px;
    border-left: 1px solid #c6c6c6;
}

p.state_search {
    border-bottom: 1px solid #000;
}

p.state_search a {
    float: right;
}

div.state_search_results {
    width: 32%;
    float: left;
}

.margin_left {
	margin-left: 10px;
}

#proctor-form-id {
	display: none;
}

div.sec_adboxrow {
	float:right;
	margin:0 10px 10px 10px;
	width: 185px;
}
div.sec_adboxrow table, table.sec_full_adbox {
	width: 100%;
	border: 1px solid #555;
}
div.sec_adboxrow table th {
	color: #fff;
	background-color: #000;
	font-size:8pt;
}
div.sec_adbox_large {
	clear: both;
}

.margin_top_ten {
	margin-top: 10px;
}
.margin_bottom_ten {
	margin-bottom: 10px;
}

.small_content_boxes .standard_box {
    width:320px;
	margin: 10px;
	float: left;
}
.small_content_boxes .standard_box .top {
    width:296px;
}
.small_content_boxes .standard_box .bottom {
    width:296px;
	margin-top:-7px;
}

.small_content_boxes .standard_box  .content_clear {
	border-left: 1px solid #c6c6c6 !important;
	border-right: 1px solid #c6c6c6 !important;
	width:318px !important;
	padding:0 !important;
	clear:both;
}
.small_content_boxes .standard_box .light_grey_background {
	background-color: #e4e4e4;
}

.small_content_boxes .standard_box .split_table .col_1 {
	float:left;
	display:block;
	width:31%;
	padding:8px 8px 8px 10px;
	text-align:center;
}
.small_content_boxes .standard_box .split_table .col_2 {
	float:left;
	display:block;
	width:31%;
	border-left:1px solid #c6c6c6;
	padding:8px 8px 8px 10px;
	text-align:center;
}

.small_content_boxes .standard_box .split_table .col_3 {
	float:left;
	display:block;
	width:29%;
	border-left:1px solid #c6c6c6;
	padding:8px 8px 8px 10px;
	text-align:center;
}

.small_content_boxes .standard_box .split_table .left_column_top,
.small_content_boxes .standard_box .split_table .left_column {
	float:left;
	display:block;
	width:65%;
}

.small_content_boxes .standard_box .split_table .left_column p,
.small_content_boxes .standard_box .split_table .right_column p {
    padding:8px 8px 8px 10px;
}
.small_content_boxes .standard_box .split_table .right_column p {
	text-align: center;
	width: 100%;
}

.small_content_boxes .standard_box .split_table .left_column_top p {
    margin-left:20px;
}

.small_content_boxes .standard_box .split_table .right_column_top {
	float:left;
	display:block;
	width:35%;
    text-align:center;
}

.small_content_boxes .standard_box .split_table .right_column {
    padding:0 !important;
}

.small_content_boxes .standard_box .bottom_crnr_left {
    margin-top:-7px;
}

.small_content_boxes .standard_box .bottom_crnr_right {
    margin-top:-7px;
}

div.standard_box div.box_header_bordered div.box_header_bordered_content {
	clear:both;
	border-left:1px solid #c6c6c6;
	border-right:1px solid #c6c6c6;
	border-bottom:1px solid #c6c6c6;
	font-size:12px;
	font-weight:bold;
	text-align:center;
	padding-bottom:6px;
}

div.standard_box div.box_header_bordered {
	height:30px;
	background:#f7f7f7 url(/images/cfa/corners/round_box_header_bg.gif) top center repeat-x;
}

div.white_box div.top_crnr_left {
	background:url(/images/cfa/corners/corner_tl.png) top left no-repeat;
	height:12px;
	width:12px;
	float:left;
	margin-left:-1px;
}

div.white_box div.top {
	float:left;
	border-top:1px solid #c6c6c6;
	height:11px;
}

div.white_box div.top_crnr_right {
	background:url(/images/cfa/corners/corner_tr.png) top right no-repeat;
	height:12px;
	width:12px;
	margin-right:-1px;
	float:left;
}

div.stateTitle {
    float: left;
    width: 375px;
    margin-bottom: 12px;
    font-weight: bold;
}

div.stateValue {
    float: left;
    margin-left: 16px;
}

.ssp_tal_stats {
    margin: 0;
    border: 0;
}

.ssp_tal_wrapper {
    border: 1px solid #666;
    width: 494px;
}

.ssp_tal_title_row {

    width: 494px;
    border-bottom: 1px solid #999;
    background: #efefef;
}

.ssp_tal_title {
    font-size: 1.5em;
    display: inline-block;
    width: 488px;
    margin: 3px 0 5px 5px;
    line-height: 24px;
}

.ssp_tal_colhead_row {
	width: 494px;
    background: #ccc;
}

.ssp_date_head_row {
    display: inline-block;
    width: 494px;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    background: #efefef;
    line-height: 20px;
}

.ssp_date {
	position: relative;
	left: 5px;
}

.ssp_tal_row {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    width: 494px;
}

.ssp_no_bdr {
    border-bottom: 0;
}

.ssp_current_bg {
    background: #c5e5e5;
}

.ssp_tal_task_name {
	display: inline-block;
	margin: 3px 0 5px 5px;
	line-height: 16px;
    width: 220px;
 }

.ssp_tal_status {
	display: inline-block;
	margin: 3px 0 5px 0;
	line-height: 16px;
    width: 55px;
}

.ssp_tal_comp_date {
	display: inline-block;
	margin: 3px 0 5px 0;
	line-height: 16px;
    width: 120px;
}

.ssp_tal_score {
	display: inline-block;
	margin: 3px 0 5px 0;
	line-height: 16px;
    width: 70px;
}

.ssp_tal_ctr {
    text-align: center;
}

a.ssp_normal {
    color: #333;
}

a.ssp_overdue {
    color: #900;
}

a.ssp_complete {
    color: #060;
}

.ssp_status_complete {
    width: 16;
    height: 16;
    border: 0;
    position: relative;
    top: 2px;
}


/*
    Change password form
*/
form.chg-password table {
    margin: 20px 0;
}
form.chg-password .err-msg {
    font-weight: bold;
    color: red;
}

.stuaffirmnote {
 font-weight: bold;
 font-style: italic;
 clear:both;
 margin-top: 50px;
}

/*
    Interstitial Dialog Notification Message
    related: interstitial.js
*/
#interstitialDialog {
    text-align: center;
}
p.caution-notify {
    text-align: left;
    font-weight: bold;
    padding: 14px 0 14px 55px;
    background: url("http://media.schweser.com/images/icons/warning.png") no-repeat scroll 0 0 transparent;
}
#interstitialDialog div.buttons {
    margin: 1em auto 0 auto;
    width: 380px;
    overflow: hidden;
}
#interstitialDialog div.buttons.single {
    width: 268px;
}
#interstitialDialog div.buttons a {
    margin: 0 38px;
    padding: 5px 40px;
}
#interstitialDialog .remind-option {
    display: block;
    float: right;
    width: 165px;
    color: #808080;
    font-size: 8pt;
    padding: 8px 0 15px;
}
#interstitialDialog .remind-option input {
    height: 13px;
    margin: 0 5px 0 0;
    padding: 0;
    position: relative;
    top: -1px;
    vertical-align: bottom;
    width: 13px;
}
#cfa_footer_text {
    color: #444444;
    font-family: Helvetica,Arial,sans-serif;
    font-size: 10px !important;
    line-height: 16px;
    margin: 6px;
}

.grey_strikethrough {
	text-decoration: line-through;
	color: #ccc;
}
.center-button {
	text-align: center;
	width: 100%;
}

/* ----------- sso self enroll ----------- */

.error_message {
	color:#FF0000;
	font-weight: bold;
}
#column2_checkbox.div.error_message {
	float:right;
	clear:left;
	margin:0 0 10px 0;
}

.attestation_error {
  padding: 8px 35px 8px 14px;
  font-size: 13px;
  margin-top: 5px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border: 1px solid #eed3d7;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #f2dede;
  color: #b94a48;
  display:none;
}
.attestation_view{
  display:block;
}

input.error_message {
	border: 2px solid #FF0000;
	margin-bottom:9px;
}
select.error_message {
    border: 2px solid #FF0000;
    margin-bottom:9px;
}
.formErrors{
    color: #FF0000;
    font-weight: bold;
}
.column1 {
    float: left;
    width: 21%;
    margin:0 auto;
    padding:5px 0 0 0;
    height: auto;
}
.column2 {
    float: left;
    width: 68%;
    height: auto;
}
#column2_phone {
    float: left;
    width: 42%;
    height: auto;
}
#column2_extLabel {
    float: left;
    width: 8%;
    height: auto;
	padding: 3px;
}
#column2_extInput {
    float: left;
    width: 31%;
    height: auto;
}

#column2_checkbox {
    float: left;
    height: auto;
}
.input_class_phone {
    float: none;
    width: 141px;
}

.input_class_extInput {
    float: none;
    width: 75px;
}
.input_class {
    float: none;
    width: 275px;
}
.select_dropdown {
    float: none;
    width: 275px;
}
.phoneNote {
    clear: left;
    margin: 0 0 10px 0;
}
.hiddenDiv {
    display: none;
}

.ferpaMainTable{
	background-color: #FFFFFF;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 779px;
}
div.ferpa_contents {
    background-color:white;
    width:94%;
    margin:10px auto;
    padding: 10px;
}
div.ferpa_contents h2 {
    margin-top: 40px;
    border-bottom: 3px solid #000080;
}
ul.ferpa_fields {
    list-style-type: disc;
}
div.ferpa_contents div.ferpa_choice_description {
    background-color: #F5F5F5;
    padding: 12px 12px;
    margin-top: 15px;
    margin-bottom: 15px;
}
input.ferpa_button {
    background-image: url('/images/button_images/button_shader.png');
    background-position: center top;
    background-repeat: repeat-x;
    color: white;
    font-weight: bold;
    font-size: 11px;
    cursor: pointer;
    padding-bottom: 6px;
    padding-top: 9px;
}
input.ferpa_button_yes {
    background-color:#48D189;
    border: thin solid #349162;
}
input.ferpa_button_no {
    background-color:#EC6969;
    border: thin solid #A94E4D;
}

.smallborder {
    border-bottom: 1px solid #CCC;
    border-top: 1px solid #000000;
}

.label {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
}

.label {
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
}

.label:empty {
  display: none;
}

a.label:hover,
a.label:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.label-important {
  background-color: #b94a48;
}

.label-important[href] {
  background-color: #953b39;
}

.label-warning {
  background-color: #f89406;
}

.label-warning[href] {
  background-color: #c67605;
}

.label-success {
  background-color: #468847;
}

.label-success[href] {
  background-color: #356635;
}

.label-info {
  background-color: #3a87ad;
}

.label-info[href] {
  background-color: #2d6987;
}

.hide-pricing.hide-pricing.hide-pricing {
        opacity: 0 !important;
}

#results_processing {
    position: fixed;
    top: 60% !important;
    left: 55% !important;
    background-color: #e0e0e0;
    padding: 15px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    z-index: 2000;
}

.content-main-body .full-width-elem {
    width: calc(100% + 56px);
    padding: 0px;
    margin-left: -28px;
}

.general-inline-filters {
    background: #fff;
    border: 1px solid #ddd;
    border-width: 1px 0px;
    padding: 8px;
    width: calc(100% + 40px);
    margin-left: -28px;
    margin-bottom: 8px;
}

.general-inline-filters .shared-row {
    margin-top: 30px;
}

.use-bootstrap-pager.ui-tablePager-table .ui-tablePager-interface {
    display: none;
}

.use-bootstrap-pager.ui-tablePager-table,
.use-bootstrap-pager.ui-tablePager-table th {
    border-bottom: 1px solid #ddd;
}

.use-bootstrap-pager.ui-tablePager-table th {
    background: none !important;
}

.use-bootstrap-pager.ui-tablePager-table th,
.use-bootstrap-pager.ui-tablePager-table td {
    padding: 8px 16px;
}

.use-bootstrap-pager.ui-tablePager-table tr:nth-child(odd) td {
    background: white;
}

.placeholder {
    color: #aaa !important;
}

table.recampus a {
    font-size: 10pt;
}
